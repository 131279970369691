.contact{
   
    &__row{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        
    }
   
    &__mapContent{
        width: 60%;
    }
    &__textContainer{
        min-width: 250px;
        background-color: rgba(228, 228, 228, 0.267);
        padding: 20px;
        border: 1px solid rgba(226, 226, 226, 0.6);
        box-shadow: 0 0 10px 5px rgba(194, 194, 194, 0.2);
        &__left{
            min-width: 250px;
        }
        &__right{
            min-width: 250px;
        }
        &__title{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
    a{
        text-decoration: none;
        display: block;
    }
    margin: 50px 0;
    &__imageContainer{
        width: 100%;
        img{
            width: 100%;
        }
    }
    &__content{
        border-bottom: 1px solid rgb(218, 218, 218);
        padding: 5px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: left;
        &:hover{
            background-color: #349a8715;
            
            }
    }
    &__contentHref{
        display: inline-block;
    }
    &__contentHref.email{
        color: black;
        &:hover{
        color: #349a87;
    
        }
        
    }
    &__contentHref.number{
        color: black;
        font-size: 17px;
        &:hover{
        color: #349a87;

        }

    }
    @media(min-width: 1400px){
        &__mapContent{
            width: 50%;
        }
    }
    @media(max-width: 500px){
        &__row{
            display: block;
        }
        &__textContent{
            padding-top: 20px;
        }
        &__mapContent{
            width: 95%;
            margin: 0 auto;
        }
        &__textContainer{
            max-width: 95%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &__left{
                min-width: 220px;
            }
            &__right{
                min-width: 220px;
            }
        }
    }
    @media(max-width: 1000px){
        &__row{
            display: block;
        }
        &__mapContent{
            width: 95%;
            margin: 0 auto;
        }
        &__textContainer{
            max-width: 400px;
            width: 90%;
            margin: 20px auto;
        }
    }
}
.ymaps-2-1-79-gototech{
    display: none  !important;
}
.ymaps-2-1-79-copyright__content{
    display: none  !important;
}