

.dropdownMenu{
    visibility: hidden;
    top: 70px;
    left: 0;
    position: fixed;
    height:100%;
    width: 100%;
    background-color: #349a87;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    transition: all 0.4s;
    &__logo{
      height: 60px;
      width: auto;
      cursor: pointer;
    }
  &__catalogBox{
    margin: 0 auto;
  }
}

@media (max-width: 1000px){
    .dropdownMenu__catalog{
  display: none;

}
}
.dropdownMenu.active{
    visibility: visible;
    opacity:1;
    overflow: hidden;

    .sidebar{
      text-align: center;
      margin: 0 auto;
    }
    .sidebar__block{
      text-align: center;

    }
    }


