.transparentButton{
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 200px;
    border: 1px solid aliceblue;

    cursor: pointer;
    &:hover{
    background-color: aliceblue;
    transition: 0.3s;
    span{
        color: #393838;
    }
    }
    &:active{
    background-color: rgb(43, 120, 188);
    transition: 0.2s;

    }
span{
    margin: 0 auto;
    padding: 10px;
    font-size: 17px;
    color: aliceblue;
    text-transform:uppercase;
}
}