.carouselReactSlick{
max-width: 1200px;
&__name{
    padding: 20px;
    height: 60px;
    overflow: hidden;
}
button{
    color: black;
    width: 55px;
    z-index: 100;
    height: 55px;
    transition: all 0.3s;
    &::before{
        font-size: 35px;
    color: black;
        
    }
    &:hover{
        background-color: rgba(0,0,0,.5);
    }
    &:active{
    transition: 0.1s;

        background-color: rgba(0,0,0,.2);
    }
}
a{
    text-decoration: none;
    
}
margin: 0 auto;
&__container{
    height: 300px;
    width: 400px; 
    margin: 0 auto;
   
}
&__boxImages{
    height: 180px;
    max-width: 400px;
    outline: none;
    display: flex;
    align-items: center;
    margin: 0 auto;
  
    img{
        max-height: 200px;
        max-width: 200px;
       object-fit: cover;
margin: 0 auto;
    }
}
&__boxText{
    padding: 5px 25px;
    position: relative;
}
&__boxPrice{
    display: flex;

margin-top: 20px;
}
&__price{
    margin-right: 15px
}
&__priceTwo{
    color: #ACACAC;
    text-decoration:line-through;
}
@media (max-width: 1000px){ 

    img{
        max-height: 200px;
        max-width: 200px;
       
    }
}
@media (max-width: 600px){ 
    &__name{
        padding: 0 20px;
    height: 40px;

    }
    &__boxPrice{
        margin-top: 0;
    }
    &__boxImages{
        height: 200px;
        max-width: 200px;
        outline: none;
        display: flex;
        align-items: center;
        margin: 0 auto;
        img{
            max-height: 200px;
            max-width: 200px;
    margin: 0 auto;
        }
    }
}

}