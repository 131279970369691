.blackButton{
  margin: 15px;
  display: flex;
  align-items: center;
  height: 55px;
  width: 200px;
  background-color: #2e353f;
  border: 1px solid aliceblue;
  transition: 0.3s;
  cursor: pointer;
  &:hover{
  background-color: aliceblue;
  transition: 0.3s;
  span{
      color: #2e353f;
  }
  }
span{
  margin: 0 auto;
  padding: 10px;
  font-size: 17px;
  color: aliceblue;
  // text-transform:uppercase;
}
}

.whiteButton{
  margin: 15px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  height: 55px;
  min-width: 100px;
  max-width: 300px;
  background: rgba(0,0,0,.3);
  border: 1px solid rgba(0,0,0,.3);
  transition: all 0.3s;
  cursor: pointer;
  &:hover{
  background-color: rgba(255,255,255,.9);
  span{
    color: #000000;

  }
  }
span{
  margin: 0 auto;
  padding: 10px;
  font-size: 13px;
  color: white;
  text-transform:uppercase;
  transition: all 0.2s;
  
}
}