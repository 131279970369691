.architectSkills{
    min-height: 300px;
    background-color: #7448ff;
    font-family: 'PT Sans', sans-serif;
&__block{
    margin-top: 30px;
    &:hover svg{
        transform: scale(1.3); 
         cursor: pointer;
  }
}
&__icon{
    text-align: center;
    svg{

        color: rgb(255, 255, 255);
        height: 90px;
        width: 90px;
        transition: all 0.4s;
    
    }
 

}

&__description{
    text-align: center;
    color: rgb(255, 255, 255);
    margin: 40px 0;
    font-size: 15px;
    padding: 0 20px;
}

}