.requestForCalculating{
    height: 130px;
    width: 100%;
    background: #2e353f;
    a{
        text-decoration: none;
    }
&__containerButton{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
} 