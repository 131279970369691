.applicationPage{
    &__headerContent{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__contentBlock{
        position: relative;
        border: 1px solid #d6d6d6;
        margin-bottom: 10px;
        padding: 10px;
        
    }
        &__contentBlock.active{
            -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.41); 
            box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.41);
        }
    &__productImgBox{
        width: 150px;
        overflow: hidden;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 100%;
            width: auto;
        }
    }
    &__descriptionProblemHeader{
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 1px;
    }
    &__descSliceText{
        margin-left: 20px;
        &:hover{
            text-decoration: underline;
        }
    }
    &__renderDetails{
        margin-top: 20px;
    }
    &__data{
        margin-right: 30px;
        font-weight: 600;
    }
    &__iconBox{
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
    }
    &__detailsContent{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: 90%;
        border-bottom: 1px solid #d6d6d6;
        padding: 5px;
    }
    &__detailsContent.changeProfile{
        position: relative;
        min-height: 50px;
    }
    &__detailsLabel{
        text-align: left;
        font-weight: 600;
    }
    &__detailsLabel.changeProfile{
        width: 50px;
    }
    &__detailsText{
        text-align: left;
        max-width: 60%;
        font-size: 14px;
        color: #a6a6a6;
    }
    &__detailsText.changeProfile{
        width: 100px;
    }
    &__detailsText.noneText{
        color: red;
    }
    &__detailsContentSvg.changeProfile{
        position: absolute;
        right: -15px;
        cursor: pointer;
        &:hover{
            svg{
                color: rgb(138, 138, 138);
            }
        }
    }
}