@import './components/all';
@import './container/all';
@import './button/all';
@import './mainPage/all';
@import './variables';
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import 'animate.css';

:root {
  --background-color: #ffffff;
  --text-color: #131313;
  --svgCategoryIcon: none;
  --labelColor: "black";
  --svgMainIcon: invert(1) sepia(1) saturate(5) hue-rotate(180deg);
}

[data-theme='black'] {
  --background-color: #393939;
  --text-color: rgb(177, 177, 177);
  --svgCategoryIcon: invert(1) sepia(1) saturate(5) hue-rotate(180deg);
  --labelColor: "black";
  --svgMainIcon: none;
}

* {
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  -webkit-overflow-scrolling: touch; /* Включение скроллбара */
  scrollbar-color: orange #f9f9fd; /* Цвет ползунка и фона скроллбара */
  scrollbar-width: thin; /* Толщина скроллбара */
}

* {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f9f9fd;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: orange;
  }
  
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    background-color: #f9f9fd;
  }
}

body {
  font-family: 'PT Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.input {
  background-color: -internal-light-dark(rgb(255, 255, 255), rgba(255, 255, 255, 0.4)) !important;
}

h1 {
  color: var(--text-color);
}

a {
  text-decoration: none;
}

ul {
  padding-left: 5px;
}
