.containerContentCabinet{
    padding: 20px;
    h1{
        margin: 15px auto;
        color: var(--text-color);
        text-align: center;
    }
    @media(max-width: 500px) {
        h1{
            font-size: 20px;
            max-width: 200px;
        }
    }
}