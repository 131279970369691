.dropdownDetails {
    width: 100%;
    border: 1px solid rgb(214, 214, 214);
    margin: 10px auto;
    .key{
        font-size: 22px;
    }
    .value{
        font-size: 18px;
        font-weight: 600;
        color: #7700ff;
    }
    &__textGray{
        color: #c4c4c4;
    }
    &__partnerIconBox{
        background: #afafaf;
        padding: 5px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover{
            background: #c1c1c1c4;
        }
    }
    &__header-left{
        display: flex;
        align-items: center;
        padding: 10px;
        &__text{
            font-size: 23px;
        }
    }
    &__icon{
        margin: 0 20px ;
        height: 25px;
        width: 25px;

    }
    &__container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;    
    }
    &__icons{
    margin-right: 10px;
    }
    &__contentBox{
        padding: 15px;
        margin: 10px;
    }
    &__container-button{
        max-width: 500px;
        // height: 40px;
        margin: 20px auto;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

    }
    &__button{
        width: 100%;
        height: 100%;
        background-color: #00a046;
        color: #fff;
        border: none;
        border-radius: 4px;
    }
    &__button:active{
        border: none;
    }
    &__edit-login-container{
        display: flex; 
        text-align: center;
        cursor: pointer;
    }
    &__edit-login-container:hover{
       color: red;
    }
    &__edit-login-icon {
        margin-right: 10px;
    }
    &__edit-login-text{
        font-size: 18px;
    }
    @media(max-width: 500px){
        &__header-left{
            &__text{
                font-size: 17px;
            }
        }
    }
}