.elementPaymentPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
  font-size: 14px;
  line-height: 1.714rem;
  font-weight: 400;
  font-family: "Helvetica", "Arial", "Roboto", sans-serif;
  color: #1a1a1a;
  text-align: left;
  background-color: white;
  //   height: 100vh;
  &__content {
    margin-left: 45px;
  }
  &__mainTitle {
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 2.286rem;
  }
  &__itemInfoContent {
    margin-bottom: 16px;
    // font-weight: bolder;
    span {
      font-weight: bolder;
    }
  }
  &__itemDescriptionContent {
    margin-bottom: 16px;
  }
  &__imgBoxRow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 767px) {
      display: block;
      &__imgBox {
        margin: 0 auto;
      }
    }
  }
  &__imgBox {
    display: flex;
    width: 100%;
    line-height: 1.3em;
    img {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
}