.architect{
    font-family: Montserrat;
&__mainImage{
    height:500px;
    min-height: 100%;
    // background: url(../../assets/4.jpg) no-repeat center center; 
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    &__text{
        position: relative;
        top: 70%;
        text-align: center;
        color: aliceblue;
        text-shadow: 1px 1px 1px rgb(0, 0, 0);
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.4;
        font-family: Montserrat;
        transition: all 0.5s;
        max-width: 400px;
        margin: 0 auto;
    }
    }
&__descriptionContainer{
    margin: 30px auto;
    max-width: 1000px;
    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__left{
        max-width: 300px;
        margin-top: 20px;
        text-align: left;
        color: black;
        padding: 20px;
        height: 100%;
        font-weight: 600;
        font-size: 27px;
        line-height: 35px;
    }
    &__right{
        max-width: 50%;
        h2{
            font-size: 15px;
            padding: 20px 0;
            text-align: center;
         } 
         p{
            font-size: 12px;
            color: rgb(113, 113, 113);
            line-height: 14px;
         }
    }
 h1{
 
 }

}
@media(max-width: 500px){
    &__descriptionContainer{
        &__left{
                max-width: 90%;
                margin: 0 auto;
                text-align: center;
        }
        &__right{
            max-width: 90%;
            margin: 0 auto;
        }
    }
}
}


