.burgerIcon-close{
    color: white;
    &:hover{
        cursor: pointer;
        color: red;
    }
}
.mainBurgerIcon{
    cursor: pointer;
    
    &:hover{
        .burgerIcon{
        &__burger-btn::after{
            content: '';
            position: absolute;
            top: 4px;
            background-color: #349a87;
            height: 3px;
            border-radius: 5%;
            transform: translateX(-10px);
    
        }
        &__burger-btn::before{
            content: '';
            position: absolute;
            bottom: 4px;
            background-color: #349a87;
            width: 35px;
            border-radius: 5%;
            height: 3px;
            transform: translateX(-10px);
        }
        &__burger-btn span {
            content: '';
            position: absolute;
            top: 16px;
            background-color: #349a87;
            width: 35px;
            height: 3px;
            border-radius: 5%;
             transform: translateX(10px);
        }
     }
     @media(max-width: 500px){
        .burgerIcon{
        &__burger-btn::after{
            background-color: white;
            transform: none;
        }
        &__burger-btn::before{
            background-color: white;
            transform: none;
        }
        &__burger-btn span {
            background-color: white;
            transform: none;
        }
        }
    }
    }
}
.burgerIcon {
    position: relative;
    height: 35px;
    margin-right: 40px;
    transition: all 0.2s;
    padding: 10px;

    &__burger-btn::after{
        content: '';
        position: absolute;
        transition: 0.5s all;
        top: 4px;
        background-color: white;
        width: 35px;
        height: 3px;
        border-radius: 5%;

    }
    &__burger-btn::before{
        content: '';
        position: absolute;
        transition: 0.5s all;
        bottom: 4px;
        background-color: white;
        width: 35px;
        border-radius: 5%;
        height: 3px;
    }
    &__burger-btn span {
        content: '';
        position: absolute;
        transition: 0.5s all;
        top: 16px;
        background-color: white;
        width: 35px;
        height: 3px;
        border-radius: 5%;
       
    }
}