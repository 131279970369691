.containerMainComponent{
    background: var(--background-color);
    overflow-x: hidden;
    min-height: 300px;
    border-top: 1px solid #f0f0f0;
    &__title{
        font-size: 34px;
        text-transform: uppercase;
        font-weight: 600;
        margin: 40px auto;
        text-align: center;
        color: black;
    }
    &__description{
        color: black;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 50px;
    }
    @media(max-width: 500px){
        &__title{
            font-size: 22px;
            margin: 20px auto;
        }
    }
}  