.buttonBoxFullWidth{
    position: relative;
    &__content{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2691ec;
    cursor: pointer;
    bottom: 0;
    transition: 0.3s all;
    &:hover{
        background-color: #2780ce;
    }
}
    &__button{
        color: #ffffff;
        font-size: 18px;
    }
    .disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    p{
        top: -30px;
        right: 0;
        left: 0;
        position: absolute;
        text-align: center;
        color: red;
        font-weight: 600;
    }
}
