.footer {
    bottom: 0;
    height: auto;
    width: 100%;
    background-color: #349a87;
    background: url(../../assets/6.jpg) no-repeat center center;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    &__socialMediaIcons{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__allRightsReserved{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    &__row{
      margin: 0;
    }
    &__ButtonContainer{
      .orderCatalog-white{
        border: 1px solid #e6e6e6;

      }
    }
    &__ButtonContainer-header{
      display: none;
      .orderCatalog-white{
        border: 1px solid #e6e6e6;
      }
    }
    &__categoryTitle{
      font-size: 20px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 15px;
    }
&__header{
  padding: 20px;
width: 100%;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
  h2{
    color: white;
    margin: 10px auto;
  }
  input{
    color: white;
  }
}
&__headerInputContainer{
display: flex;
margin: 0 auto;
right: 0;
width: 100%;
.materialInput{
  margin-right: 20px;
  label{
    font-size: 15px;
  }
}
}
&__headerSendButton{
  position: relative;
  top: -10px;
  right: 40px;
  width: 40px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  svg{
  position: absolute;
  top: 10px;
  left: 10px;
  color: rgb(239, 239, 239);
  &:hover{
    color: rgb(73, 80, 211);
  }

  }

}
&__whiteBlock{
  background-color: rgba(43, 43, 43,.8);
  height: 100%;
  width: 100%;
  margin: 0 auto;

  span{
    font-size: 20px;
    color: #ffffff;
  }
}
&__title{
  display: flex;
  align-items: center;
}

&__addressPhoneBox{
  display: flex;
  margin-bottom: 10px;
}

&__infoLeft, &__infoRight{
  cursor: pointer;
  padding: 20px;
  width: 33%;
  height: 50px;
  color: #e6e6e6;
h5{
  transition: 0.3s all;
  &:hover{
    color: rgb(88, 88, 88);
  }
}
  span{
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
  transition: 0.3s all;
}
span, h5{
  &:hover{
    color: rgb(168, 168, 168);
    cursor: pointer;
}

}
}

&__companyName, &__companyNumber{
  margin-bottom: 40px;
  margin-top: 10px;
}

@media screen and (max-width: 750px) {
  &__categoryTitle{
    display: none;
  }
  &__displayNone{
    display: none;
  }
  &__addressPhoneBox{
    display: block;
  padding-bottom: 20px;
  }
  
  &__ButtonContainer-header{
    display: none;
  }
  &__ButtonContainer{
    display: none;
  }
  &__header{
    // margin-left: 20px;
    width: 100%;
    
      // h2{
      //   display: none;
      // }
    }
    &__headerInputContainer{
      display: none;
    }
  &__companyName, &__companyNumber{
    display: none;
  }
  &__sidebar li{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    color: #349a87;
  }
&__seasonSummer,&__seasonWinter{
display: none
} 
&__infoLeft, &__infoRight {
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: auto;
  padding: 0;

}
}

@media(max-width:500px){

  &__header{
    justify-content: center;
    padding: 10px;
    h2{
      font-size: 16px;
      text-align: center;
    }
  }
  &__infoLeft, &__infoRight{
    span{
      font-size: 16px;

    }
  }
}
  }