.renderProductOrder{
    margin: 10px 0 ;
    position: relative;
    border: 1px solid #797878;
    padding: 10px;
    &__moreIcon{
        position: absolute;
        right: -5px;
        top: 5px;
    }
    &__row{
        display: flex;
        justify-content: left;
        align-items: center;
        position: relative;
        // flex-wrap: wrap;
    }
    &__contentBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: inline-block;
    }
    &__name{
        position: absolute;
        bottom: 30px;
        right: -10px;
        font-weight: 600;
        text-align: left;
        width: 80px;
        a{
            color: #3e77aa;
            font-size: 16px;
            text-align: center;
        }
        &:hover{
            a{
                color: red;
            }
        }
    }
    &__imgBox{
        margin-left: 20px;
        max-width: 250px;
        height: 100px; 
        min-width: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        overflow: hidden;
        position: relative;
        img{
            max-width: 100%; /* Изображение будет заполнять всю доступную ширину */
            max-height: 100%; /* Изображение будет заполнять всю доступную высоту */
            object-fit: contain;
        }
    }
  
    &__quantity{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 5px;
    }
    &__title{
        font-size: 12px;
        color: #797878;
        margin-right: 10px;
    }
    &__value{
        text-align: center;
    }
    @media(max-width: 800px){
        &__imgBox{
        margin: 10px auto;
        }
        &__row{
            display: block;
        }
    }
}