.likedBlock{
    &__iconContainer{
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &:hover {
            .likedBlock__iconBox{
            border: 1px solid #69b815;
            }
            .likedBlock__promptBlock{
                overflow: hidden;
                opacity:1;
                transition: 0.4s all;
            a{
                border-bottom: 1px dashed #ACACAC;
                &:hover{
                    color: red;
                }
            }
        }
        .likedBlock__saveButtonBlock{
            color: red;
        }
        svg{
                color: #69b815;
            }
        }
    }
    &__iconDesiresBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__iconBox{
        width: 30px;
        margin-right:5px ;
        height: 30px;
        line-height: 27px;
        border: 1px solid #ACACAC;
        border-radius: 50%;
       
        svg{
            color: #ACACAC;
            position: relative;
            left: 7px;
            font-size: 15px;
        }
    }
    &__likedBlock{
        display: flex;
        align-items: center;
        transition: 3s;
        }
    &__promptBlock{
        overflow: visible;
        opacity:0;
        position: absolute;
        width: 200px;
        background: rgba(0,0,0,.7);
        right: 90px;
        bottom: -20px;
        border-radius: 5px;
        padding: 10px;
        color: white;
    }
}
.likedBlock.active{
    .likedBlock__iconBox{
        border-color: rgb(255, 0, 0);
        svg{
            color: rgb(255, 0, 0);
        }
    }
}