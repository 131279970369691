.renderCharacteristic{
&__title{
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
}
&__contentContainer{
    padding-bottom: 10px;
}
&__name{
    font-weight: 700;
    color: #000;
    font-size: 16px;
}
&__description{
font-size: 16px;
}
}