.textView {
    text-align: right;
    &__content{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        max-width: max-content;
        margin-left: auto;
        &:hover{
            .textView__text{
                color: rgb(136, 136, 136);
            }
            svg{
                color: rgb(136, 136, 136);
            }
        }
    }
    &__icon{
        margin-right: 5px;
    }
    &__text{
        font-weight: 600;
        font-size: 18px;
    }
}