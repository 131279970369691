.inputFullBorder {
    width: 100%;
    min-width: 200px;
    height: 50px;
    border-radius: 3px;
    border: 1px solid #cecece;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    background-color: #cecece28;
    position: relative;
  
    &__iconBox {
      svg {
        color: #00a8e6;
        margin: 0 10px;
      }
    }
  
    &__redDot {
      color: red;
      position: absolute;
      top: -25px;
    }
  
    &__label {
      position: absolute;
      top: -25px;
      font-size: 0.928em;
      line-height: 20px;
      color: #777777;
      &::after {
        content: "*";
        color: red;
        margin-left: 5px;
      }
    }
  
    &__label.focus {
      // left: 30px;
    }
  
    &__input {
      background: transparent;
      width: 100%;
      outline: none;
      border: none;
      height: 100%;
      // line-height: 45px;
    }
  
    input[type="text"] {
      padding-left: 10px;
    }
  
    // &__input.focus{
    //     background: red;
    // }
  
    .inputFullBorder__control {
      border: none;
      box-shadow: none;
    }
  
    .inputFullBorder__value-container {
      padding: 0;
    }
  
    .inputFullBorder__menu {
      margin-top: 0;
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px -1px;
      z-index: 10;
      background-color: #ffffff;
    }
  
    .inputFullBorder__option {
      color: #444444;
      padding: 10px;
      &:hover {
        background-color: #f5f5f5;
      }
      &.inputFullBorder__option--is-selected {
        background-color: #00be2318;
        height: 100%;
        color: #00be23;
        &:hover {
          background-color: #00be2318;
        }
      }
    }
  
    &.focus {
      border-color: #00a8e6;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background-color: #ffffff;
    }
  
    &.filled {
      border-color: #00be23;
      box-shadow: none;
      background-color: #00be2318;
    }
  }
  .css-13cymwt-control{
    height: 100%;
  }