.personalInformation{

    &__alreadySendBlock{
        width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0;
    border-bottom: 1px solid rgb(211, 211, 211);
    }
    &__text{
        font-weight: 600;

    }
    button{
        margin-left: auto;
    }
}