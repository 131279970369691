.itemGallery{
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    &:hover{
        &::after{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; 
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, .3);
            z-index: 2;
        }
    }
   &__galleryPage{
    &__moreIcon{
        position: absolute;
        top: 5px;
        right: 0;
    }
   }
}