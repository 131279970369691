.renderItemOrder{
    padding: 15px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin-top: 8px;
    transition: 0.3s all;
 
    &__mainRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    &__colorBlock{
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        height: 100%;
        width: 8px;
        border-radius: 50px;
        transition: all .2s ease-in-out;
    }
    &__numberDataRow{
        margin-left: 20px;
        span{
            font-size: 12px;
            // color: #797878;
        }
    }
    &__statusText{
        font-size: 14px;
    }
    &__imgSvgContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 400px;
        overflow: hidden;
    }
    &__imgContainer{
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__imgBox{
        margin-right: 10px;
        height: 50px;
        width: auto;
        img{
            height: 100%;
            width: auto;
        }
    }
    &__numberOrderAdditional{
        color: #cc00ff;
        font-weight: 600;
    }
    @media(max-width: 1000px){
        &__mainRow{
            display: block;
        }
        &__imgSvgContainer{
        max-width: 95%;

            justify-content: space-between;
            margin-left: 20px;

        }
    }
}

