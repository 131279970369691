.app__main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.app__main-container > *:not(:last-child) {
  margin-bottom: 50px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 100%;
  width: 100%;
}
.main-container {
  max-width: 100%;
  display: flex;
  align-items: center;
}

.window {
  height: 100%;
  width: 100%;
  overflow: hidden;

}

.all-pages-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: translate;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.arrow {
  cursor: pointer;
}
