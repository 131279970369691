.fixedButtonsBottomFullScreen {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
    width: 100%;
    max-width: 400px; /* Максимальная ширина блока */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px 10px 0 0; /* Скругленные верхние углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.5s ease-in-out; /* Плавная анимация выезда */
}

.fixedButtonsBottomFullScreen__icon {
    cursor: pointer;
    padding: 10px;
    color: #ffffff; /* Белый цвет для иконок */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Иконка занимает всю ширину контейнера */
}

.fixedButtonsBottomFullScreen__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@media(max-width: 600px){
.fixedButtonsBottomFullScreen__icon {
    padding: 0px;
}
}