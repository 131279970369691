.purpleButton{
    // margin: 15px 0;
    display: flex;
    align-items: center;
    z-index: 1;
    height: 55px;
    min-width: 200px;
    max-width: 320px;
    background-color: #7448ff;
    border: 1px solid aliceblue;
    transition: 0.3s;
    
    cursor: pointer;
    &:hover{
    background-color: rgb(62, 62, 62);
    transition: 0.3s;
    span{
        color: #ececec;
    }
    }
span{
    margin: 0 auto;
    padding: 10px;
    font-size: 13px;
    color: aliceblue;
    text-transform:uppercase;
    letter-spacing: 1px;
}
}

.grayButton{
    display: flex;
    margin: 0 auto;
    align-items: center;
    z-index: 1;
    height: 55px;
    min-width: 200px;
    max-width: 320px;
    background-color: #b5b5b5;
    border: 1px solid aliceblue;
    transition: 0.3s;
    cursor: pointer;
    &:hover{
    background-color: rgb(62, 62, 62);
    transition: 0.3s;
    span{
        color: #ececec;
    }
}
span{
    margin: 0 auto;
    padding: 10px;
    font-size: 13px;
    color: aliceblue;
    text-transform:uppercase;
    letter-spacing: 1px;
}
}