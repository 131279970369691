.buttonProduct{
    margin: 10px auto;
    border-radius: 5px;
    background: #349a87;
    color: rgb(27, 27, 27);
    margin: 0 auto;
    border: 3px solid #FFF;
    padding: 9px 10px;
    text-align: center;
    transition: all 0.3s;
    a{
        text-decoration: none;
    }
    &:hover{
        background: #29ac94;
        color: #fff;
        border: 3px solid rgb(219, 219, 219);

    }
    &__hrefContainer{
        width: 100%;
        height: 100%;
    }
}
.buttonProduct.active{
    background: #007bff;
}