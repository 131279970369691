.orderCatalog{
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
    position: fixed;
    z-index: 99999;
    height: 45px;
    width: 200px;
    background-color: #349a87;
    top: 50%;
    border: 1px solid aliceblue;
    transition: 0.3s;
    left: -78px;
    cursor: pointer;
    &:hover{
    background-color: #393838;
    transition: 0.3s;
    }
span{
    margin: 0 auto;
    font-size: 16px;
    color: aliceblue;
    text-transform:uppercase;
}
@media (max-width: 600px){
display: none;
}

}   
.orderCatalog-white{
    margin: 15px;
    display: flex;
    align-items: center;
    z-index: 1;
    height: 55px;
    min-width: 200px;
    max-width: 300px;
    background-color: #349a87;
    text-transform:uppercase;
    // border: 1px solid aliceblue;
    transition: 0.1s all;
    
    cursor: pointer;
    &:hover{
    background-color: rgb(217, 217, 217);
    span{
        color: #393838;
    }
    }
span{
    margin: 0 auto;
    padding: 10px;
    font-size: 17px;
    color: aliceblue;
    text-transform:uppercase;
}
@media (max-width: 600px){
        margin: 5px;
        height: 45px;
        min-width: 100px;
        max-width: 200px;
        span{
            font-size: 12px;
        }
    }   
}  