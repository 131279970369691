.productFeatureDefault{
    min-height: 400px;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    margin: 20px;

    &__containerContent{
        display: flex;
        padding-top: 20px;
    }
    &__title{
        position: relative;
        align-self: flex-start;
        width: 40%;
        font-size: 14px;
        word-break: break-word;
        span{
            position: relative;
            display: inline-block;
            padding-right: 16px;
            background-color: #fff;
        }
        &:before{
            content: "";
            position: absolute;
            left: 0; 
            bottom: 10px;
            display: none;
            width: 100%;
            border-bottom: 1px dotted #d2d2d2;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__desc{
        width: 60%;
        padding-left: 16px;
        box-sizing: border-box;
    }
    &__descriptionContainer{
        margin: 20px 0;

        span{
            max-width: 500px;
        }
    }
    @media(min-width: 500px){
    
        &__descriptionContainer{
            display: none;
        }
    }
    @media(max-width: 500px){
&__containerContent.full{
    display: none;
}
        
        padding: 5px;
        margin: 0;
    }
}