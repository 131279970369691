.darkLineComponent{
    // height: 160px;
    width: 100%;
    background-color: rgb(46, 53, 63);
    &__leftBlock{
        width: 100%;
        height: 160px;
        h1{
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: right;
            color: rgb(255, 255, 255);
            font-size: 40px;
            text-align: right;
            line-height: 30px;
            font-family: 'Courier New', Courier, monospace;
            letter-spacing: 5px;
        line-height: 1.2;
        font-weight: bold;
        }
    }
    &__rightBlock{
        width: 100%;
        height: 160px;
        p{
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: left;
            color: rgb(255, 255, 255);
            font-size: 25px;
            text-align: left;
            position: relative;
            left: -15px;
            line-height: 30px;
            font-family: 'Courier New', Courier, monospace;
        }
    }
    &__bottomBlock{
        width: 100%;
        &__textBlock{
            margin-top: 30px;
            h4{
                color: white;
                text-align: center;
                margin-top: 15px;
                font-size: 18px;
            }
        }
        &__button{
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__categoryHref{
            cursor: pointer;
            text-decoration: underline;
            &:hover{
                color: red;
            }
        }
    } 
    
    @media (max-width: 544px){
        &__leftBlock{
            h1{
                align-items: center;
                margin: 0 auto;
                justify-content: center;
                text-align: center;
            }
        }
        &__rightBlock{
            padding-left: 30px;
            p{
            text-align: center;
            }
        }
    }
}

