.footerMainPage{
    position: relative;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2);
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    &__rowContent{
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

        font-size: 16px;
        margin-bottom: 20px;
    }
    &__listBlock{
        width: 200px;
    }
    &__list, &__email{
        padding-bottom: 10px;
    }
    &__iconSiteBlock.top{
        display: none;
    }
    &__iconSiteBlock.bottom{
        display: block;
    }
    &__iconSiteBlock{
        img{
            width: auto;
            height: 50px;
        }
    }
    &__year{
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }
    @media(max-width: 500px){
        &__iconSiteBlock.top{
            display: block;
        }
        &__iconSiteBlock.bottom{
            display: none;
        }
        &__rowContent{
            display: block;
        }
        &__listBlock{
            margin-left: 20px;
        }
        &__iconSiteBlock{
            margin: 10px auto;
            text-align: center;
        }
        &__list{
            font-size: 18px;
        }
    }
}
.footerMainPage.background{
    background-color: #2e353f;
}