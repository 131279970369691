.imgListComponent{
    max-width: 1200px;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;

    h1{
        color: black;
        text-align: center;
        padding: 30px 10px; 
    }
    &__row{
        text-align: center;
    }
    &__contentBlock{
        margin: 10px;

    }
    &__imgContainer{
        max-width:800px;
        margin: 0 auto;

        img{
            width: 100%;
        }
    }
}