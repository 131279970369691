.miniGrayButton{
    padding: 10px;
    background-color: #f5f5f5;
    box-shadow: inset 0 0 0 1px #cfcfcf;
    max-width: 200px;
    min-width: 100px;
    height: 40px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
    &:hover{
        background-color: #e0e0e0;
        transition: 0.3s;
}

span{
    margin: 0 auto;
    font-size: 11px;
    color: #3e77aa;
    text-transform:uppercase;
    letter-spacing: 1px;
}
}
.miniGrayButton.miniGrayHref{
    min-width: 50px;
    height: auto;
}
.miniGrayButton.greenButtonHref{
    span{
        color: #3e77aa;
    }
    &:hover{
        background-color: #349a87;
        transition: 0.3s;
        span{
            color: #ffffff;
        }
}
}
.miniGrayButton.medium{
    background-color: #ff0000b7;
    
    span{
        color: #000000;
        
    }
    &:hover{
        background-color: #e0e0e0;
        transition: 0.3s;
}
}