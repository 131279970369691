.products{
    margin-top: 20px;
    position: relative; 
}

.productListItems{
    margin: 20px 0;
   
    &__mainContainer{
        z-index: 500;
        min-height: 250px;
    }
    &__contentMainContainer{
        position: relative;
    }
    &__emptyProducts{
        font-size: 17px;
        max-width: 500px;
        margin: 10px auto;
        color: #3f4147;
        text-align: center; 
    &__iconBox{
        padding: 5px;
        background-color: #3f414716;
        width: 60px;
        margin: 15px auto;
        text-align: center;
        cursor: pointer;
        svg{
            font-size: 50px;
        }
        &:hover{
            background-color: #3f41472d;
        }
    }
    }
&__productTitle{
  min-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 0 0 0  ;
    a{
        margin-top: 15px;
        color: #3f4147;
        font-size: 20px;
        text-decoration: none;
        line-height: 22px;
    }
}
&__productPrice{
    font-size: 23px;
    font-weight: 400;
    color: white;
    text-shadow: 1px 1px 1px #000000;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
}
&__buttonBlock{
    text-align: center;
    position: absolute;
    margin: 0 auto;
    top: -20px;
    right: 0;
    left: 0;
}
    &__pages{
        text-align: center;
        margin: 20px 0;

    }
    &__page, &__currentPage {
        border:1px solid lightgray;
        border-radius:100%;
        padding:5px 10px;
        margin:0 10px;
        cursor: pointer;
    }
    &__currentPage{
        border:2px solid black;
        font-weight: 600;

    }
    a{
        overflow: hidden;
    }
    &__imageContainer{
        max-width: 1200px;
        width: 90%;
        margin: 0 auto;
        min-height: 500px;
    }
    &__mainTitle{
        padding-top: 40px;
        text-align: center;
            margin: 10px auto;
        h1{
            display: inline-block;
            color: black;
            position: relative;

            text-align: center;
            z-index: 1;
            &::after{
                content: attr(data-title);
                position: absolute;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                min-width: 18px;
                padding: 2px;
                font-weight: 600;
                top: -2px;
                right: -25px;
                font-size: 15px;
                border-radius: 50%;
                background-color: #e7e7e7;
                z-index: 0;
            }
        }
}
  &__title{
      text-align: center;
      margin: 20px auto;    
  }
&__descriptionContainer{
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}
&__descriptionContainer::after{
    content: '';
    top: 25px;
    right: 50%;
    width: 1px;
    background-color: #e7e7e7;
    height: 70%;
    position: absolute;
}
&__buttonContainer{
    margin:30px;
    .orderCatalog-white{
        margin: 0 auto;
        &:hover{
            background-color:  #2e353f;
            span{
                color: white;
            }
        }
    }
}
&__textBlock{
    position: absolute;
    transition: 0.5s all;
    max-height: 100%;
    z-index: 1;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    opacity: 0;
   h1,h2{
    color: aliceblue;
   }
   &__name{
    color: aliceblue;
    font-size: 20px;
    font-weight: 600;
    position:absolute;
    text-transform: uppercase;
    top: 30%;
    text-align: center;
    transition: 0.3s all;
    transform: translateY(20px);
    left: 0;
    right: 0;
   }
   .whiteButton{
        position: absolute;
        width: 250px;
        height: 55px;
        left: 0;
        right: 0;
            transition: 0.3s all;
            transform: translateY(20px);
            bottom: 30%;
   }
    z-index: 5;
}
    &__imageBox{
        width: 100%; 
        height: 100%;
        text-align: center;
        margin: 0 auto;      
        display: flex;
        justify-content: center;
    }
    &__likeButton{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            transition: all 0.3s;
            width: 40px;
            height: 40px;
            color: rgb(255, 255, 255);
            &:hover{
                    color: #6fcf97;
            }
        }
    }
&__likeButton.active{
    svg{
        color: rgb(255, 0, 0);
    }
}
&__zoomButton{
    cursor: pointer;
    svg{
        transition: all 0.3s;
        width: 40px;
        height: 40px;
        color: rgba(0, 0, 0, 0.6);
        &:hover{
                color: rgb(255, 255, 255);
               transform: scale(1.1);
        }
    }
}
&__showButtonBlock{
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(46, 53, 63,.6);
        transition: 0.3s all;
        opacity: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

       
        svg{
            width: 40px;
            height: 40px;
            visibility: visible;
            color: rgba(255, 255, 255, 0.7);
          &:hover{
            width: 45px;
            height: 45px;
            visibility: visible;

            color: rgb(255, 255, 255);
          }
        }
        
}
&__showButtonBlock.active{
    opacity: 1;
}
&__imageBlock{
        height: 300px;
        margin: 0 auto;
        border-radius: 10px;
        text-align: center;
        transition: all 0.5s;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
    img{
        transition: 0.3s all;
        object-fit: cover;
        max-width: 100%;
        text-align: center;
        height: 100%;

    }
    &:hover .productListItems__textBlock{
    opacity: 1;
        &::before{
            content: '';
            z-index: 0;
            background-color: rgba(0,0,0,.2);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    &__name{
        font-size: 16px;
    transform: translateY(0px);
    }
    .whiteButton{
    transform: translateY(0px);

    }
    }
}
@media (max-width: 1100px){
    &__imageBlock{
        height: 200px;
}
}
@media (max-width: 770px){
    &__descriptionBlock{
        padding-bottom: 20px;
        text-align: center;
    }
    &__descriptionContainer::after{
    content: '';
    top: 55%;
    left: 0;
    right: 0;
    width: 70%;
    background-color: #2e353f;
    height: 1px;
    margin: 0 auto;
    position: absolute;
    }
}
@media (max-width: 544px){

    &__mainTitle{
        padding-top: 10px;
        h1{
            font-size: 18px;
            &::after{
                content: attr(data-title);
                font-size: 12px;
            }
        }
        
    }
    &__likeButton{
        svg{
            &:hover{
                color: rgb(255, 0, 0);
            }
        }
    }
    &__likeButton.active{
        svg{
            color: rgb(255, 0, 0);
        }
    }
    &__imageBlock{
        height: 100%;
        max-width: 100;
        margin: 0 auto;
        img{
            text-align: center;
            width: 100%;
        }
    }
    &__textBlock{
       opacity: 1;
       &__name{
           text-align: center;
           left: 0;
           right: 0;
           bottom: 30%;
           font-size: 20px;
       }
       .whiteButton{
        left: 0;
        right: 0;
        bottom: 50px;
       }
    }
}

&__imageBox{
    width: 100%;
    height: 100%;

    img{
    width: 100%;
    height: 100%;
    }
}
}