.renderDescription{
    &__sizeContainer{
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    }
    &__title{
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 17px;
        width: 100px;
    }
        &__peculiaritiesContainer{
    padding-left: 10px;
    }
    &__peculiaritiesList{
        padding-bottom: 5px;
    }
    &__sizeContainer{
        padding-left: 0;
    }
}