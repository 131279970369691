.descContainer{
    &__nameBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    &__name{
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-left: 5px;
        font-size: 18px;
        height: 32px;
        overflow: hidden;
        line-height: 16px;
        color: #221f1f;
    }
    &__rowPrice{
        display: flex;
        align-items: center;
        justify-content: space-between;
       
    }
    &__priceFalse{
        font-size: 18px;
    }
    &__cartIconContainer{
        cursor: pointer;
        svg{
            color: #3e77aa;
            font-size: 22px;
            &:hover{
                color: red;
            }
        }
    }
    &__cartIconContainer.gray{
        svg{
            color: #00a046;
        }
    }
    &__price{
        font-size: 24px;
    }
    &__yesN{
        color: #00a046;
    }
    &__noN{
        color: #797878;
    }
}