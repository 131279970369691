.renderAskQModal{
    min-height: 400px;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
h1{
    font-size: 25px;
    color: black;
}
    &__inputContainer{
        margin-top: 30px;
        color: black;
        text-align: center;
    }
    textarea{
    background-color: #ffffff;
      width: 90%;  
      min-height: 100px;
    color: black;
    } 
    label{
        color: black;
    }
    &__checkboxBlock{
        margin-top: 20px;
    }
}
.renderAskQModal.black{
    min-height: 400px;
    width: 100%;
    background-color: #212529;
    padding: 20px;
    margin: 0;
   
h1{
    font-size: 25px;
}
    &__inputContainer{
        margin-top: 30px;
        text-align: center;
    }
    textarea{
      background-color: #212529; 
      width: 90%;  
      min-height: 100px;
      color: white;
    }
    label{
        color: white;
    }
    &__checkboxBlock{
        margin-top: 20px;
    }
}