.settingIconBlock{
    top: 0;
    right: 0;
    cursor: pointer;
    &:hover{
        
        svg{
            color: rgb(255, 0, 0);
        }
    }
}