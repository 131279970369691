.favoriteIcon{
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 3;
        svg{
            height: 30px;
            width: 30px;
        }
        &:hover{
            svg{
                color: red;
            }
    }
}