

.header{
    position: fixed;
    height: 80px;
    width: 100%;
    transition: all 0.4s;
    z-index: 9999999999999;

  
}

.headerTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    transition: 0.3s;
    left: -78px;
    cursor: pointer;
    &:hover{
    // background-color: #221f1f;
    transition: 0.3s;
    }
}

.headerNav{
    display: flex;
    color: black;
    position: relative;
    top: 0; 
    align-items: center;
    z-index: 1;
    height: 80px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    z-index:999;   
    &__WPlus{
        display: block;
        @media(max-width: 700px){
            display: none;
    }
}
    &__WPlusAbsolute{
        display: none;
        @media(max-width: 700px){
            position: absolute;
            top: 86px;
            width: 100vw;
        }
     
    }
    &__WPlusAbsolute.searchShow{
        display: block;
        @media(min-width: 700px){
            display: none;
        }
    }
    &__WMinusShow{
        display: none;
        @media (max-width: 800px){
            display: block;
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            width: 100%;
        }
    }
    &__MiniModalNumber{
        text-decoration: none;
        color: #1e37dc;
        margin: 20px 0;
        span{
            margin: 20px 0;

        }
    }
    &__cartIcon{
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
            svg{
                color: #ff4747;
            }
        }
        span{
            height: 18px;
            width: 18px;
            font-weight: 600;
            position: absolute;
            top: -10px;
            right: 2px;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: #ff4747;
        }
    }
    &__personContainer{
        display: flex;
        margin-right: 5px;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        span{
            margin: 5px 0;
            color: white;
        }
        &:hover{
            span,svg{
                color: #ff4747;
            }
        }
    }
    &__personContainer.activePersonName{
        background-color: #ffffff;
        z-index: 20;
        border-radius: 5px;
                span,svg{
                    color: black;
                }
            }
  

    a{
        text-decoration: none;
        transition: 0.3s all;
       
    }
    &__iconContainer{
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;
        svg{
            width: 24px;
            height: 24px;
            margin-right: 10px;
            color: white;
        }
    }
    &__searchIcon{
        cursor: pointer;
        display: none;
        svg{
            margin: 5px;
        }
        &:hover{
            background-color: rgba(255, 255, 255, 0.1);
            svg{
                color: #ff4747;
            }
        }
        @media(max-width: 700px){
            display: block;

        }
    }

    &__miniModal{
        position: absolute;
        background-color: #fff;
        top: 50px;
        right: 5px;
        width: 220px;
        min-height: 100px;
        border-radius: 5px;
        z-index: 9999999;
        box-shadow: 0 4px 10px 0 #5b5b5b; 
    }
    &__miniModal.rightModal{
        position: fixed;
        top: 0;
        right: -200px; /* изменено с -500px на -200px */
        height: 100vh;
        width: 250px;
        background-color: #64aad3;
        border-radius: none;
        box-shadow: 0 4px 10px 0 #5b5b5b; 
    }
    &__miniModal.rightModal.visible {
        transition: all 1s;
        right: 0;
    }
    &__contentModal{
        // padding: 20px;
        position: relative;
    }
    &__closeMiniModal{
        position: absolute;
           cursor: pointer;
           color: white;
           right: 5px;
           top: 12px;
           border-radius: 2px;
           padding: 5px;
           background-color: #ff0000;
           transition: all 0.3s;
           &:hover{
           background-color: #040404;
           }
          }
    &__MHeaderTitle{
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #c6bcbc;
    }
    &__buttonContainer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__button{
        padding: 10px;
        margin: 0 5px;
        border-radius: 2px;
        background: #c6bcbc;
        cursor: pointer;
        &:hover{
        background: rgba(198, 188, 188,.5);  
        }
    }
    &__button.active{
        background: #ff4747;
        &:hover{
            background: rgba(255, 71, 71,.8);  
            }
    }

    &__logo{
        color: rgb(0, 0, 0);
        transition: 0.5s;
        cursor: pointer;
            &:hover{
            color: #393838;
        }
    }
    &__logo
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #ffffff 0%,
    #6100c8 29%,
    #13ff13 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
    &__logo.active{
        color: rgb(255, 255, 255);
    }
    &__number{
        color: rgb(0, 0, 0);
        transition: 0.5s;
        cursor: pointer;

    }
    &__number.active{
        color: rgb(255, 255, 255);
    }
    @media (max-width: 420px){

    }

    @media (max-width: 350px){
        &__personContainer{
            span{
                display: none;
            } 
            
        }
    }
    @media (max-width: 800px){
        &__personContainer{

            span{
            margin: 0;

                font-size: 11px;
            }
            }
&__logo, &__number{
    display: none;
}
}
@media (max-width: 700px){ 
  height: 100px;

  &__searchInputContainer{
    position: absolute;
  }
}
}






