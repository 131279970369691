.sidebar{
&__block{
// height: 50px;
max-width: 300px;
list-style-type: none;
transition: all 0.5s;
border: 1px solid #349a87;
&:hover{
    border: 1px solid  aliceblue;
    transition: all 0.5s;

}
a{  
    height: 100%;
    font-size: 20px;
    display: flex;
    align-items: center;
   color: aliceblue;
   text-decoration: none;
}
}
&__block{

    a{
    padding: 10px 5px;

    }
}
&__block-black{
    // height: 50px;
    padding: 10px 5px;
    margin: 2px 10px;
    max-width: 300px;
    list-style-type: none;
    transition: all 0.5s;
    // border: 1px solid white;
    &:hover{
        // border: 1px solid  #ffc75f;
        transition: all 0.5s;
    a{
       color: #349a87;

    }
    }
    a{
        font-size: 20px;
        display: flex;
        align-items: center;
       color: rgb(255, 255, 255);

       text-decoration: none;
    }
    }
    @media(max-width:500px){
        a{  
            font-size: 16px;
        }
    }
}