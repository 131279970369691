.logoMain.standard{
    img{
        height: 70px;
        width: auto;
        cursor: pointer;
    }
}
.logoMain.mini{
    img{
        height: 50px;
    }
}