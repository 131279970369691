.seasonCatalog{
    &__title-block{
        padding-top: 20px;
        height: 40px;
        width: 200px;
        background-color: #0c9;
        display: flex;
        align-items: center;
        transition: 0.3s;
        font-size: 16px;
        color: rgb(255, 255, 255);
        &:hover{
        background-color: rgb(0, 142, 106);
        transition: 0.3s;
        }
            span{
                text-transform: uppercase;
                font-weight: bold;
                margin: 0 auto;
                width: 100%;
            }

    }
    &__title-block-black{
        height: 40px;
        width: 100%;
        text-align: center;
        // background-color: #0c9;
        display: flex;
        cursor: pointer;
        align-items: center;
        transition: 0.3s;
        font-size: 16px;
        color: rgb(255, 255, 255);
      
            span{
                text-transform: uppercase;
                font-weight: bold;
                margin: 0 auto;
            }

    }
    ul{
        margin-top: 10px;
        padding-left: 5px;
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
&__block{
    list-style-type: none;
    a{
        font-size: 16px;
        display: flex;
        align-items: center;
       color: rgb(255, 255, 255);
       text-decoration: none;
       
       transition: all 0.3s;
    &:hover{
        position: relative;
        // color: black;
        transform: translate(5px);
        &::after{
            content: '';
            position: absolute;
            left: -10px; 
            color: wheat;
        }
    }
    
    }
}
&__block-black{
    list-style-type: none;
    &:hover{
        a{
            color:#9d9d9d ;
        }
    }
    a{
        font-size: 16px;
        display: flex;
        align-items: center;
       color: rgb(255, 255, 255);
       text-decoration: none;
       transition: all 0.3s;
       padding: 5px;
       text-transform: uppercase;
    &:hover{
        position: relative;
        // color: black;
        text-overflow: initial;
        white-space: normal;
        transform: translate(10px);
      
    }
    
    }
}
}