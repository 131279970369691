

.sliderMiniImg {
    max-width: 600px;
    min-height: 300px;
    height: auto; 
    max-height: 600px;
    margin: 0 auto; 
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
   .prev {
        position: absolute;
        top: 5%;
        right: 120px;
        height: 50px;
        width: 50px;
        transition: 0.3s all;
        border: none;
        outline:none;
        background: rgba(255, 255, 255, 0.8);

        &:hover{
            background: rgba(0,0,0,.5);
            color: #f1f1f1;
        }
        &:active{
            color:#c272ff;
            background: rgba(0,0,0,.9);
        }
    }
   .next {
        position: absolute;
        top: 5%;
        right: 60px;
        height: 50px;
        width: 50px;
        transition: 0.3s all;
        outline:none;
        background: rgba(255, 255, 255, 0.8);
        border: none;
        &:hover{
            background: rgba(0,0,0,.5);
            color: #f1f1f1;
        }
        &:active{
            color:#c272ff;
            background: rgba(0,0,0,.9);
        }
    }
    &__renderMiniImgBox{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__slide1 {
        max-width: 500px;
        min-height: 300px;
        position: absolute; 
        opacity: 0;
        transition: opacity ease-in-out 0.4s;
        display: flex; 
        align-items: center;
        justify-content: center;
        
        &.active-anim {
            opacity: 1;
        }
    }
    &__mainContent {
        position: relative;
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: center;
          }
    }
    
    &__fullZoomButton.active {
        opacity: 1;
    }
    &__fullZoomButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &__zoomButton{
        cursor: pointer;
        color: white;
        transition: opacity 0.3s ease;
        z-index: 999999;
        svg{
           width: 40px;
           height: 40px;
           visibility: visible;
           color: rgba(255, 255, 255, 0.7);
         &:hover{
           width: 45px;
           height: 45px;
           visibility: visible;
           color: rgb(255, 255, 255);
         }
       }
    }
    &__containerMiniImg {
        margin: 10px 0;
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    &__miniImg {
        height: 100px;
        width: auto;
        max-width: 200px;
        position: relative;
        display: flex;
        justify-content: center;
        margin:5px;
        active-mini {
            opacity: 0.8;
        }
        img {
            height: 100%;
            width: auto;
            overflow: hidden;
        }
        &:last-child{
            margin-right: 0
        }
        &__iconFunctional{
        position: absolute;
        left: 5px;
        top: 5px;
        cursor: pointer;
        filter: drop-shadow(0 0 1px rgb(255, 0, 0));
        svg{
            color: #000000;
            
        }
        &:hover{
            svg{
            color: red;
        }
        }
        }
    }

    .active-anim {
    opacity:1;
    overflow: hidden;
    }
    &__btn-slide1 {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #f1f1f1;
    border: 1px solid rgba(34, 34, 34, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        width: 20px;
        height: 20px;
        pointer-events: none;
      }
  }


&__container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}
&__dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    margin: 0 5px;
    background: #f1f1f1;
}
&__dot.active {
    background: rgb(32, 32, 32);
}

@media (min-width: 1000px){ 
    min-height: 350px;
    max-height: 400px;
    &__slide1 {
        img{
    max-height: 350px;
        }
        // height: 200px;
        // width: 100%;
        // top: 0;
    }
    .prev {
        top: 5%;
        right: 70px;
    }
   .next {
        top: 5%;
        right: 15px;
    }
}
@media (min-width: 350px) and (max-width: 1000px) {
    &__mainContent {
        img {
            max-height: 400px;
          }
    }
}
@media (min-width: 1000px){ 
    min-height: 400px;
    &__mainContent {
        img {
            height: 400px;
          }
    }
}
@media (min-width: 1370px){ 
    max-width: 850px;
    min-height: 500px;
    max-height: 1000px;
    &__slide1 {
        max-width: 600px;
    }
    &__mainContent {
        img {
            width: auto;
            height: 500px;
            max-width: 100%;
            overflow: hidden;
          }
    }
}
@media (min-width: 1500px){
    &__slide1 {
        top: 0;
    img {
        display: block;
        width: 100%;
}
    }
    .prev {
        top: 5%;
        right: 70px;
    }
   .next {
        top: 5%;
        right: 15px;
    }
}
}