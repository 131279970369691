.renderBlackModal{
    min-height: 400px;
    width: 100%;
    background-color: #212529;
    padding: 20px;
   
h1{
    font-size: 25px;
}
    &__inputContainer{
        margin-top: 30px;
        
        text-align: center;
        // margin-right: 0;
    }
    textarea{
    background-color: #212529; 
      width: 90%;  
      min-height: 100px;
    //   padding-right: 20px;
    color: white;
    }
    label{
        color: white;
    }
    &__checkboxBlock{
        margin-top: 20px;
    }
    
}