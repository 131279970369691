.breadcrumb{
    width: 100%;
    margin-bottom: 0;

    .breadcrumb-item{
        a{
            font-size: 12px;
        }
        &:hover{
            a{
                color: red;
            }
        }
    }
    @media(max-width: 600px){
    padding: 5px;
        .breadcrumb-item{
            a{
                font-size: 10px;
            }
        }
    }

}