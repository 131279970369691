.aboutUs{
&__containerImage{
    background-color: #2e353f;
    margin-bottom: 20px;
}
&__blockImage{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

img{
    width: 100%;
    transition: all 0.5s;
    cursor: pointer;
    &:hover{
transform: scale(1.1); 
    }
}
    }

&__blockText{
    h2 {
        color: aliceblue;
        text-align: center;
        margin: 20px 0;
        font-weight: 600;
    }
    p{
        color: aliceblue;
        padding: 0 20px;
        font-size: 16px;
    }
}
@media(max-width:500px){
    &__blockText{
        p{
        font-size: 14px;
        }
    }
}
}