.fullModal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: none;
    background: #000; 
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1200000000;
    display: flex;
    justify-content: center;
    align-items: center;
    &__mainContainer{
      position: relative
    }
&__title{
    color: #000;
    font-size: 20;
    padding: 10px;
}
&__modalContainer{
      max-width: 1200px;
      max-height: 700px;
      background-color: #fff;
      border-radius: 4px; 
      margin-top: 0;
      display: flex;
      margin: 0 auto;
    }
&__modalContainer.transparent{
      background-color: rgba(255, 255, 255, 0);
      width: 100vw;

    }
&__modalContainer.miniModal{
      width: 600px;

    }
    &__closeContainer{
      position: relative;
      
    }
    &__modalClose.outsideCloseIcon{
        position: fixed;
        z-index: 99999;
        right: 0;
        color: rgb(216, 216, 216);
        cursor: pointer;
        top: 0;
        transition: all 0.2s;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 22px;
        &:hover{
          color: rgba(255, 0, 0, 0.9);
        background-color: rgba(0, 0, 0, 0.1);

        }
      }
    &__modalClose.insideCloseIcon{
        position: absolute;
        z-index: 99999;
        right: 15px;
        color: rgb(216, 216, 216);
        cursor: pointer;
        top: 10px;
        transition: all 0.2s;
        &:hover{
          color: rgba(255, 0, 0, 0.9);
        }
      }
    @media(max-width: 1000px){
      &__modalContainer{
    max-width: 1000px;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    margin: 0 auto;
  }

  }
    @media(max-width: 650px){
    &__modalContainer.miniModal{
      width: 300px;

    }
    }
    &__modalContainer.cartFullMini {
      @media(max-width: 1000px){
        &__modalContainer{
      max-height: 100vh;
      background-color: #fff;
    }
  
    }
    }
    }
    .fullModal.cartFullMini{
        @media(max-width: 800px){
            background-color: #fff;
            display: block;
          &__modalContainer{
            max-height: 100%;
            
            }
        }
      
    }