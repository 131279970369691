.socialMediaIcons{
  z-index: 2;
  display: flex;
  
  &__container{
    position: relative;
    margin:10px;
    display: block;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: rgba(51, 51, 51,.6);
    color: rgba(255, 255, 255, 0.9);
    opacity: 0.9;
    transition: all 0.2s;
    &:hover{
      color: rgb(26, 26, 26);
    background-color: #eaeaea;

    }
  }
  svg{
    position: absolute;
    font-size: 25px;
    // color: rgb(111, 111, 111);
    display: block;
    margin: 0 auto;
    top: 6px;
right: 6px;

  }
}