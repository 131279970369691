.blueLoader{
    height: 100vh;
    background-color: rgba(0, 0, 0,.1);
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__container{
        margin: 50px auto;
        height: 50px;
        width: 200px;
        border-radius: 3px;
        background-color: #97cce2;
        box-shadow: 0 4px 10px 0 #bababa;
        border: solid 1px #e5e5e5;

        p{
            padding: 5px;
            text-align: center;
            color: white;
        }
    }
}