.offlinePage {
    text-align: center;
    position: relative;
    height: 100%;
    width: 100%;
    &__mainContent{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    h1 {
        position: relative;
        margin-top: 30px;
        text-align: center;
        color: rgb(255, 255, 255);
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
        font-family: Montserrat;
    }

    h2 {
        color: #333;
        font-size: 1.5rem;
        margin-top: 20px;
        animation: fadeInUp 1s ease-out;
    }

    p {
        color: #555;
        font-size: 1rem;
        margin-top: 20px;
        line-height: 1.5;
        animation: fadeInUp 1s ease-out;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
