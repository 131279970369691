.landscaping {
    max-width: 1200px;
    margin: 15px auto;
  
    &__imgBlock {
      padding: 10px;
      max-height: 500px;
      display: flex;
      flex-direction: column;
    }
  
    .container-slider {
      width: 100%;
      cursor: pointer;
      margin: 0 auto;
      max-height: 600px;
      max-width: 1000px;
      transition: 0.2s all;
  
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.15);
      }
    }
  
    &__contentBlock {
      padding: 5px;
      height: 100%;
      margin-top: auto;
    }
  
    &__imgOneContainer {
      max-width: 100%;
      height: 100%;
        
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
        transition: all 0.4s;
      }
    }
  
    &__imgTwoContainer {
      max-width: 100%;
        height: 100%;
      img {
        width: 100%;
        max-height: 50%;
        transition: all 0.4s;
        margin: 0 auto;
  
        &:hover {
          // Добавьте стили для hover-эффекта, если необходимо
        }
      }
  
      &:last-child {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  
    @media (max-width: 766px) {
      .container-slider {
        width: 95%;
      }
  
      &__imgTwoContainer {
        display: none;
      }
  
      &__imgBlock {
        padding: 0;
      }
    }
  }
  