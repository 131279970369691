.checkoutPage__orderInfoBlock.fixedModal{
    position: fixed;
    margin: 0;
    right: 5%;
    
    top: 0;
    @media(max-width: 800px){
    display: none;
    }
    @media(max-width: 1400px){
        right: 0;
    }
    @media(max-width: 1200px){
        right: 0;
        width: 350px;
    }
    @media(max-width: 1000px){
        width: 300px;
    }
}
.checkoutPage{
    background-color:  #f7f7f7;
    // position: absolute;
    // z-index: 1000;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    // width: 100vw;
    // height: 100vh;
    overflow-x: hidden;
    &__orderInfoBlock{
        margin-top: 55px;
        max-width: 400px;
    }
    &__contentBox{
        h3{
            margin: 20px;
        }
    }
    &__titleH1{
        font-size: 30px;
        text-align: left;
        margin: 20px;
    }
    &__checkoutContainer{
        max-width: 1350px;
        padding: 10px 20px;
        position: relative;
        margin: 0 auto;
        h1{
            text-align: center;
            color: black;
        }
    }
    &__header{
        padding: 15px 0;
        position: relative; 
        display: flex;
        align-items: center;
        justify-content: center;
        h2{
            margin: 0;
        }
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            width: 100%;
            background-color: rgb(212, 212, 212);
        }
    }
    &__headerName{
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0,.8);


    }
    &__rowMain{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    &__modalContainer{
        margin: 10px;
        h1{
            margin-bottom: 40px;
            color: black;
            font-size: 36px;
        }
     
        }
        &__titleModal{
            position: relative;
            margin-bottom: 24px;
            padding-left: 40px;
            font-size: 16px;
     span{
        position: absolute;
        left: -20px;
        justify-content: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        width: 27px;
        background-color: rgb(234, 234, 234);
        height: 27px;
        top: 0;
     }
        }
    &__warningsBlock{
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 14px;
        margin-bottom: 16px;
        padding: 16px;
        background-color: rgba(255,169,0,.1);
         border-color: #ffa900;
         margin: 20px;
    }
    // h1{
    //     font-size: 24px;
    //     line-height: 1.25;
    //     text-align: center;
    //     color: #333333;
    //     margin-bottom: 18px;
    //     width: 100%;
    //     height: 100%;
    // }
    &__addOrderBlock{
        margin: 30px auto;
        max-width: 800px;
        
        &__button{
            margin: 30px auto;
            width: 300px;
        }
    }
    @media(max-width: 600px){
        &__titleH1{
            text-align: center;
            margin: 5px;
        }
    }
}