.architectContentBlock{
    padding: 0 20px;
    max-width: 1200px;
    margin: 50px auto;
    min-height: 500px;

&__mainTitle{
    text-align: center;
    margin: 30px 0 50px 0;
    font-size: 30px;
    font-weight: 600;
}   
&__buttonContainer{
    position: absolute;
    bottom: 0;
}
&__buttonContainer-rightImage{
    position: absolute;
    bottom: 0;
    right: 0;
}
&__imgContainer{
    display: table;
    width: 100%;
    }
    &__imgBlock{
        text-align: center;
        vertical-align: middle;
        img{
           object-fit: cover;
            width: 100%;
            text-align: center;
            max-height: 500px;

        }
    }

    &__textContainer{
        height: 100%;
        position: relative;
        padding-bottom: 45px;
        h3{
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 600;
        }
        p{
            color: #959595;
        }
    }
    @media(max-width: 500px){
        &__buttonContainer-rightImage{
            bottom: -5px;
        }
        &__imgContainer{
            margin-top: 15px;
            }
    }
}