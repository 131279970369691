.portfolioMain{
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    a{
        text-decoration: none;
    }

    &__text{
        padding-left: 20px;
        padding-right: 60px;
        margin-top: 50px;

.portfolioMain__slideText{
    min-height: 20px;
    display: none;

}
.portfolioMain__slideText.active{
    display: block;
    
}

    }

    &__arrowRightBlock{
        margin: 40px 0;
        a{
            color: rgb(0, 0, 0);
            display: flex;
            align-items: center;
            &:hover{
                color: #349a87;
            }

            svg{
                color: #349a87;
                margin-left: 10px;
                height: 30px;
                width: 30px;
            }
        }

    }
    &__videoButtonBlock{
        margin-top: 50px;
        a{
            display: flex;
            align-items: center;
            color: black;
            &:hover{
                color: #349a87;
            }
        }
        &__iconBlock{
            padding: 13px;
            border-radius: 50%;
            border: 1px solid #a582d7;
            margin-right: 20px;
        }
        
    }

}