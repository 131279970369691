.slide1FullHeight{



.btn-slide1FullHeight{
  width: 100px;
  height: 80%;
  outline: none;
  background: rgba(241, 241, 241, 0.2);
  border: 2px solid #45454529;
  transition: all 0.4s;
  z-index: 100;
  cursor: pointer;
  &:hover{
    background-color: rgba(255, 255, 255, 0.333);
  }
  svg{
    position: absolute;
    top: 45%;
    right: 25%;
    font-size: 40px;
    color: rgb(27, 27, 27); 
  }
}
.btn-slide1FullHeight.prev {
  left: 0;
  top: 50%;
  transform: translateY(-46%);
  position: fixed;
  border: 2px solid #45454529;

}
.btn-slide1FullHeight.next {
  right: 0;
  top: 50%;
  transform: translateY(-46%);
  position: fixed;
  border: 2px solid #45454529;
}
}