.minusPlusComponent{
position: absolute;
bottom: 20px;
right: 0;
&__row{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}
&__closeIconBlock{
    width: 30px;
    color: #3e77aa;
    cursor: pointer;
    &:hover{
    color: #000000;
    border: none;
    }
}
&__successBlock{
    width: 30px;
    cursor: pointer;
    svg{
        color: rgba(172, 172, 172, 0.5);
    }
}
&__successBlock.disabledCheckedIcon{
    width: 30px;
    cursor: pointer;
    svg{
        color: rgb(0, 191, 0);
    }
    &:hover{
    color: #000000;
    border: none;
    }
}
&__numberBlock{
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid gray;
    border-radius: 5px;
    input{
        border: none;
        max-width: 100%;
        text-align: center;
        outline: none;
    }
}
button::-moz-focus-inner 
{
  border: 0;
} 
&__minus,&__plus{
    width: 30px;
    height: 30px;
    font-size: 30px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3e77aa;
    cursor: pointer;
    &:hover{
    color: #000000;
    border: none;
    }
    svg{
        font-size: 20px;
    }
}
&__minus.opacity{
    color: rgba(0, 0, 0,.5);
}
&__minus.disabled{
    color: rgba(172, 172, 172, 0.5);
}
&__deleteIcon{

    svg{
        font-size: 20px;
    }
}
@media(max-width: 500px){
    &__numberBlock{
        width: 40px;
        height: 30px;
    }
    }
}