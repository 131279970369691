.portfolioPageDetails{
    // margin: 20px;
    // &__imageContainer{
    //     width: 100%;
    // }
    // &__imageBlock{

    //     img{
    //         width: 100%;
    //     }
    // }
    &__textContainer{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__textBlock{
padding: 0 30px;

        h4{
            color: #7448ff!important;
        }
        h2{
            font-size: 35px;
        }
        p{
            margin-bottom: 50px;
        }
    }
    &__theProjectPresent{
        height: 170px;
        width: 100%;
        background-color: #2e353f;
        margin: 0 auto;

        h3{
            color: white;
            padding: 20px 0;
            font-size: 30px;
            text-align: center;
        }
        span{
            
        }
    }
    &__categoryHrefBLock{
            color: white;
            text-align: center;
            margin: 0 auto;
            a:hover{
                color: red;
            }
    }
}