.twoButtonsComponent{
    max-width: 1200px;
    margin: 30px auto;
    a{
        text-decoration: none;
    }
    &__buttonsBlock{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    &__leftButton{
.purpleButton{
    width: 300px;
}
    }
    &__rightButton{
        .blackButton{
            width: 300px;
            background-color: #2e353f;
            border: 1px solid #2e353f;
            margin: 0;
            &:hover{
                background-color: #f2f5f7;

            }
        }
            }
}