.homePage{
    // top: 80px;
    height: 100vh;
    background: url(../../assets/2.jpg) no-repeat center center;
    background-repeat: no-repeat;
background-position: center center;
// background-attachment: fixed;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;

h1{
    z-index: 10;
}
}