.renderProductMaterial{
    margin: 0 auto;
    h5{
        text-align: center;
        font-weight: 600;
    }
    &__row{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    &__contentBox{
        width: 100px;
        min-height: 80px;
        text-align: center;


        img{
            width: 50px;
            height: 50px;
        }
    }
    &__textBox{
        font-size: 15px;
        text-align: center;
    }
}