.buttonPlusBackground{
    max-width: 280px;
    height: 60px;
    margin: 10px;
    background-color: #349a871a;
    border: 1px solid #349a87;
    border-radius: 4px;
    &__priceBox{
        display: flex;
        align-items: center;
    }
    &__price{
        font-size: 24px;
        display: flex;
        align-items: center;
        p{
            margin: 0;
            font-size: 18px;
            display: flex;
            align-items: center;
        }
    }
    &__contentContainer{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    &__buttonContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    background-color: #349a87;
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
    margin-left: 30px;
    cursor: pointer;
    transition: all 0.3s;
    span{
        color: white;
    }
    &:hover{
    background-color: #4bc8b1;

    }
    }
}