.containerCheckoutList{
    position: relative;
    border: 1px solid #ececec;
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-bottom: 50px;
    min-height: 300px;
    &__titleMainContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__titleBox{
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: start;
    }
    &__icon{

        svg{
            color: #2691ec;
            width: 30px;
            height: 30px;
        }
    }
    &__title{
        font-size: 18px;
        margin-left: 10px;
        color: #333;
    }
    &__buttonBox{
        margin-right: 20px;
        color: #2691ec;
        padding: 5px 10px;
        cursor: pointer;
        span{
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.8px;
        }
        &:hover{
            color: #585858;
        }
    }
    &__contentBlock{
    padding: 2px 33px 25px 84px;
    font-size: 15px;
    line-height: 20px;
    }
    &__titleChildren{

        .renderTitle{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 40px;
            width: 100%;
            height: 45px;
            border-top: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
        }
        
        .renderCartModal__cartRow{
            &:first-child{
                border-top: none;
            }
            border-right: none;
            border-left: none;
        }
    }
    
    @media(max-width: 500px){
        &__contentBlock{
            padding: 2px 15px 25px 15px;
            font-size: 15px;
            line-height: 20px;
            }
    }
&__buttonSuccessBlock{
    width: 100%;
    position: absolute;
    right: 20px;
    bottom: 20px;
    text-align: right;
}
}
.containerCheckoutList.filled{
    -webkit-box-shadow: 0px 0px 5px 0px rgba(4,122,0,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(4,122,0,1);
    box-shadow: 0px 0px 5px 0px rgba(4,122,0,1);
}
.containerCheckoutList.errorBlock{
    background-color: rgba(0, 255, 98, 0.1);
}
.containerCheckoutList.successData{
    background-color: rgba(0, 255, 98, 0.1);
    transition: all 0.5s ease-out;
}
