.regularOrNewClient{
    &__buttonBlock button{
        margin: 10px;
        border-radius: 0;
    }
    &__buttonBlock button.active{
        color: rgb(0, 200, 0);
        border-bottom: 2px solid rgb(0, 200, 0);
    }
    
}