.contactM{
  max-width: 1200px;
  margin: 0 auto;
  &__mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 70px;
    height: 100%;

    font-size: 18px;
  }
  &__mainContainer:nth-child(even) {
    flex-direction: row-reverse;
  } //фото слева текст справа и наоборот
  &__img {
    width: 50%;
    text-align: center;

    img {
      width: 90%;
      margin: 0 auto;
    }
  }
  &__contentName {
    width: 50%;
    text-align: center;
    margin-top: 50px;
    position: relative;
  }
  &__href {
    position: absolute;
    // margin-top: 50px;
    bottom: 25px;
    left: 0;
    right: 0;
  }
  &__name {
    margin-bottom: 15px;
  }

  @media (max-width: 1023px) {
    &__href {
      position: static;
      margin-top: 15px;
      // bottom: -25px;
    }
    &__mainContainer:nth-child(even) {
      display: block;
    }
    &__mainContainer {
      flex-direction: column;
      border-bottom: 1px solid gray;
      padding: 10px;
      margin-bottom: 40px;
    }
    &__img,
    &__contentName {
      width: 100%;
    }
    img {
      max-width: 70%;
    }
  }
  &__contentName {
    margin-top: 20px;
  }
  &__descriptionMain {
    // width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 500px) {
    img {
      max-width: 100%;
    }
  }
}
