.cartPage{



    .cartItems{
       &__card{
            width: 100%;
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px 0px !important;
        }
        &__cardHeader{
            display: flex;
            justify-content: space-between;
            padding: 20px 20px;
            border-bottom: 1px solid #cecece;
        }
    }
}