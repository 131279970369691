.checkoutContainer {
  width:95%;
  margin: 0 auto;
}
.checkoutStep {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
    .buttonColor{
      background: rgb(78, 78, 78);
      width: 100%;
      height: 100%;
    }
  &__header{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  &__header.active {
    background: #2874f0;


    .checkoutStep__stepTitle{
      color: rgb(255, 255, 255);
    }
  }
  &__stepNumber {
    display: inline-block;
    text-align: center;
    background: #eee;
    color: #2874f0;
    border-radius: 3px;
    font-size: 12px;
    box-sizing: border-box;
    padding: 3px 7px;
  }


  &__stepTitle {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #878787;
    margin-left: 15px;
  }
}
  .loggedInId {
    font-size: 12px;
    margin: 0px 0 10px 60px;
  }
  .addressForm {
    
    &__buttonBox{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
  }
  .addressContainer {
  box-sizing: border-box;
  padding: 10px; 
  background: #f5faff;
  border-bottom: 1px solid #ececec;

  &__formButtonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__addressInfoContent{
    display: flex;   
    // align-items: center;
  }
  &__addressInputBox{
    position: relative;
  }
  &__addressType {
    position: absolute;
    display: inline-block;
    margin: 0 10px;
    text-transform: uppercase;
    background: #eee;
    font-weight: 500;
    padding: 1px 5px;
    font-size: 10px;
    border-radius: 2px;
    color: #777777;
    top: 30px;
    left: -5px;
  }
  &__textBlock{
    padding-left: 10px;
  }
  &__addressDetail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 12px;
    color: #333;
  }
  
}

.addressContainer__addressInput {
  height: 15px;
  width: 25px;
  margin: 10px;
  // background: #333;
}
.addressinfo {
  padding-left: 15px;
  box-sizing: border-box;
  width: 100%;
}

.fullAddress {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
}
.stepCompleted {
  padding: 0 0 20px 60px;
  font-size: 12px;
  position: relative;
  &__text{
    font-size: 14px;
    font-weight: 600;
    color: gray;
  }
}
