.seasonCatalogs{
    padding:15px  50px;
    height: 100%;
    background-color: var(--background-color);
    // background-color: rgb(242, 242, 242);
    border-bottom: 1px solid rgb(133, 133, 133);
   &__textContainer{
    min-height: 80px;
   }
    &__imgContainer{
        max-width: 100%;
        overflow: hidden;
        max-height: 300px;
        cursor: pointer;
        align-items: center;
        display: flex;
        img{
            width: 100%;
            transition: all 0.5s;
            opacity: 0.9;
            display: flex;
            &:hover{
                transform: scale(1.05);
                opacity: 1;
            }
         
        }
    }

    &__blockText{
        overflow: visible;
        transition:all 0.5s;
        position: absolute;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        top: 55%; left: 0;  right: 0;
    }
    &__imgContainer:hover .seasonCatalogs__blockText{
        overflow: hidden;
        opacity:1;
    }


    @media (max-width: 500px){
        padding: 10px;
        &__textContainer{
            margin-top: 20px;
            h2{
                font-size: 20px;
            }
        }
        &__imgContainer{
            max-width: 100%;
            position:relative;
            overflow: hidden;
            cursor: pointer;
    
            img{
                width: 100%;
                transition: all 0.5s;
                opacity: 0.8;
    
                &:hover{
                    transform: scale(1.05);
                    opacity: 1;
                }
             
            }
        }
    
        &__blockText{
            overflow: visible;
            transition:all 0.5s;
            position: absolute;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            top: 50%; left: 0;  right: 0;

            .transparentButton{
                height: 40px;
                width: 150px;
            }
        }
        &__imgContainer:hover .seasonCatalogs__blockText{
            overflow: hidden;
            opacity:1;
        }
    }
}
