.applicationGalleryModal{

    &__button{
        background-color: rgba(207, 207, 207, 0.8);
        width: 200px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        transform: rotate(-90deg);
        border: 1px solid aliceblue;
        top: 50%;
        // left: 150px;
        left: -78px;
        z-index: 100;
        font-size: 17px;
        text-transform:uppercase;
        letter-spacing: 2px; 
        animation: blink 3s ease-in-out 2;
        transition: animation 0.3s;
        &:hover{
            border: 1px solid black;
        }
    }
    @keyframes empty {
    }
    @keyframes blink {
        0% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
          
        }
        75% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
}
.animation-disabled {
    animation: empty;
  }