.renderSuccessDataContent{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    &__title{
        width: 150px; 
        text-align: left; 
        margin-right: 10px;
    }
    &__content{
        font-weight: 600;
        margin-left: 20px;
    }
}
.renderSuccessDataContent.center{
    margin-left: 30px;
}