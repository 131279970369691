.descriptionItemLine{
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title{
        display: inline-flex; 
        align-items: center;
        font-weight: 600;
    }
    &__line{
        height: 1px;
        flex-grow: 1; 
        background-color: #d3d3d3;
        margin: 0 10px;
    }
    &__desc{
        width: 100px;
    }
}