.contentLabelP{
    &__label{
        font-size: 20px;
        position: relative;
        display: inline-block;
    }
    &__text{
        font-size: 18px;
        font-weight: 600;
        color: #7700ff;
    }
    &__textGray{
        color: #c4c4c4;
    }
    &__settingIcon{
        position: absolute;
        top: -2px;
        right: -25px;
        cursor: pointer;
        svg{
            color: rgb(82, 82, 82);
        }
        &:hover{
            svg{
                color: #7700ff;
            }
        }
    }
    @media(max-width: 500px){
        &__row{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 10px;
        }
        &__label{
            font-size: 15px;
        }
        &__text{
            font-size: 14px;
            position: relative;
        }
        &__textGray{
            font-size: 14px;
        }
    }
}