.tooltip-container {
    position: relative;

    button {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
  }
  
  .tooltip {
    position: absolute;
    bottom: 100%;
    background: rgba(0, 0, 0, 0.8);
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 8px;
    p{
    color: black;
        
    }
  }
  
  .tooltip::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.8);
    transform: translate(-50%) rotate(-90deg);
    left: 50%;
    top: calc(100% - 4px);
  }
  