.renderCartModal{
background-color: var(--background-color);
width: 100%;
max-width: 1000px;
min-height: 400px;
min-width: 500px;

padding: 10px;
    &__mainContentRow{
        display: flex;
            align-items: center;
            justify-content: center;
        }
    &__topTitle{
        font-size: 20px;
    }
    &__cartItemsLength{
        color: rgb(85, 85, 85);
        text-align: left;
        margin: 5px 10px;
    }
    &__cartContainer{
        height: 100%;   
    } 
    &__cartItemBox{
        max-height: 75%;
        padding: 5px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
    &__qty{
        input{
            width: 30px;
            background-color: rgb(225, 225, 225);
        }
        input[type=text] {
        text-align: center;
        }
    }
    .miniGrayButton.miniGrayHref{
        display: none;
    }
    &__cartRow{
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: flex-start;
        justify-content: space-between;
        border: 1px solid rgba(128, 128, 128, 0.33);
        margin-bottom: 10px;
      
        &:hover{
            background-color: rgb(230, 230, 230);
                input{
                    background-color: rgb(230, 230, 230);
                }
            .miniGrayButton.miniGrayHref{
                display: block;
                position: absolute;
                display: flex;
                justify-content: center;
            }
        }
    }
     &__cartRow .checkoutP{
        align-items: center;
    }
    &__settingIconBox{
        position: absolute;
        
        right: -10px;
        top: 5px;
    }
    &__cartImgContainer{
        height: 140px;
        padding: 10px;
        width: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            object-fit: cover;
            padding: 5px;
            width: 0 auto;
            text-align: center;
            height: 100%;
        }
        
    }
   &__contentBox{
    max-width: 200px;
    margin-top: 20px;
   }
    &__cartName{
        max-width: 220px;
        padding: 10px;
        padding-top: 20px;
        margin-right: 15px;
        font-size: 16px;
        text-decoration: none;
        white-space: nowrap;
    }
    &__cartName .checkoutP{
        margin-top: 0;

    }
    h2{
        margin-bottom: 20px;
    }
    &__imgContainer{
        margin-top: 10px ;
    }
    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        &::before{
            content: "";
            height: 1px;
            width: 100%;
            bottom: 0;
            left: 0;
            background-color: rgb(225, 225, 225);
            position: absolute;
        }
    }
    &__emptyCartContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
            margin-top: 20px;

        }
        p{
            margin-top: 20px;
            color: gray;
        }
    }
    &__priceContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media(max-width: 800px){
        width: 100vw;
        min-width: 200px;
        height: 90vh;
        &__cartRow{
            max-width: 95%;
            padding-bottom: 0;
        }
        &__cartName {
            font-size: 14px;
        }
    }
    @media(max-width: 500px){
        &__cartImgContainer{
            height: 140px;
            width: 140px;
        }
        &__contentRow{
            position: relative;
        }
        &__cartName {
            position: absolute;
            top: 15px;
        }
        }
}