.blog{
max-width: 1200px;
margin: 0 auto;
}

.categoryContainer{
    max-width: 1200px;
    margin: 0 auto;
 
    &__mainContainer{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        h2{
        margin:10px;
        }
    }
    &__itemBox{
        padding: 10px;
        text-transform:uppercase;
        font-size: 17;
        transition: 0.5s all;
        cursor: pointer;
        text-align: center;
    }
    &__itemBox.active{
        color: #7448ff;

        .categoryContainer__item{
            position: relative;
            &::before{
                content: '';
                height: 1px;
                width: 100%;
                position: absolute;
                bottom: -5px;
                background-color: #7448ff;
           display: flex;
           justify-content: center;
    
        }
        }

        }
        @media(max-width: 500px){
            &__mainContainer{
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              h2{
              margin: 0 auto;
              }
          }   
          }
}
.renderBlogItems{
    max-width: 1200px;
    margin: 0 auto;
    &__buttonContainerForMobile{
        opacity: 0;
    }
    &__titleHoverBox{
        font-size: 20px;
    }
    &__itemBox{
        margin: 10px;
        position: relative;
        box-shadow: 0 4px 10px 0 #bababa; 

        &:hover{
            .renderBlogItems__itemBoxHover{
                color: white;
                opacity: 1;
                position: absolute;
                background-color: rgb(174, 174, 174);
            }
        }
    }
    &__imgContainer{
        max-width: 100%;

        img{
            width: 100%;
        }
    }
    &__titleContainer{
        font-size: 20px;
        margin-bottom: 20px;
    
    }
    &__contentBox{
        padding: 20px;
    }
    &__itemBoxHover{
        opacity: 0;
    position: absolute;
display: flex;
align-items: center;
justify-content: center;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transition: 0.5s;

        .purpleButton{
            width: 200px;
            margin: 0 auto;
        }
    }
&__titleHoverBox{
    margin-bottom: 20px;
    padding:  0 20px;
    text-align: center;
}


@media(max-width: 500px){
    &__buttonContainerForMobile{
        opacity: 1;
        padding-bottom: 10px;
        .purpleButton{
            margin: 0 auto;
            max-width: 250px;
        }
    }
    &__titleHoverBox{
        display: none;
    }
    &__itemBox{
        box-shadow: 0 4px 10px 0 #bababa;
        &:hover{
            .renderBlogItems__itemBoxHover{
                opacity: 0;
                background-color: rgba(174, 174, 174,.1);
            }
        }
    }
    &__titleContainer{
        font-size: 20px;
        margin-bottom: 20px;
    
    }
    &__contentBox{
        padding: 20px;
    }
    &__itemBoxHover{
        opacity: 0;
    position: absolute;
display: flex;
align-items: center;
justify-content: center;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transition: 0.5s;

        .purpleButton{
            width: 200px;
            margin: 0 auto;
        }
    }
&__titleHoverBox{
    margin-bottom: 20px;
    padding:  0 20px;
    text-align: center;
}


}
}

.blogDetailsPage{
    margin: 0 auto; 
    max-width: 1000px;
    &__imgContainer{
        max-width: 600px;
        margin: 0 auto;
    }
     img{
         max-width: 600px;
         margin: 0 auto;
         text-align: center;
     }
     &__contentContainer{
    padding: 10px;
    h3{
        font-size: 27px;
        font-weight: 600;
        text-align: center;
    }
p{
    font-size: 20px;
}

     }
     &__videoBox{
         margin-bottom: 20px;
        height: 100%;

     }
     &__videoContainer{
         min-height: 400px;
         h1{
             color: black;
             text-align: center;
             margin: 20px 0;
         }
         p{
             font-size: 15px;
             padding: 0 20px;
         }
     }
     @media (max-width: 500px){
         img{
             max-width: 600px;
             height: 250px;
             padding: 10px;
             margin: 0 auto;
             text-align: center;
         }
         &__contentContainer{
        padding: 10px;
        h3{
            font-size: 22px;
            font-weight: 600;
            text-align: center;
        }
    p{
        font-size: 15px;
    }
    
         }
         &__videoBox{
             margin-bottom: 20px;
            // height: 100%;
    
         }
         &__videoContainer{
             min-height: 300px;
             h1{
                 color: black;
                 text-align: center;
                 margin: 20px 0;
             }
             p{
                 font-size: 15px;
                 padding: 0 20px;
             }
         }
     }
}