.productDetails{
    min-height: 500px;
  .purpleButton{
    margin: 10px auto;
  }
    &__contentContainer{
        margin: 0 50px;
    }


&__productGroup{
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #efefef;
    background: #fff;
}

&__group-item{
    padding: 20px;

}
&__name, &__price{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    h2{
        margin: 0 0 10px;
        font-size: 22px;
        display: inline-block;
        font-weight: 400;
    }
}
&__availability{
    
    margin-right: 15px;
    font-size: 12px;
    margin-top: 10px;
    color: #69b815;
}
&__vendorCode{
    margin-right: 0;
    font-size: 13px;
    padding: 7px 14px;
    background: #f9f9f9;
    border-radius: 4px;
    p{
        display: block;
        margin-bottom: 1px;
        font-size: 12px;
        color: #ACACAC;
        white-space: nowrap;
    }
}

&__priceBox{
    display: flex;
    align-items: center;
    h2{
        margin-right: 15px
    }
    h3{
        color: #ACACAC;
        text-decoration:line-through;
    }
}
&__buttonBlock{
    display: flex;
    .whiteButton{
        margin-left: 10px;
    }
}
@media (max-width: 1000px){ 
    &__priceBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__buttonBlock{
        display: block;
        .whiteButton{
            margin: 10px 0;
        }
    }
}
@media (max-width: 500px){ 
    &__contentContainer{
        margin: 5px;
    }
&__productGroup{
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #efefef;
    background: #fff;
}
&__group-item{
    padding: 10px;

}
&__name, &__price{
    display: block;
    width: 100%;
    h2{
       display: none;
    }
}
&__availability{
    margin-right: 15px;
    font-size: 12px;
    margin-top: 10px;
    color: #69b815;
}
&__vendorCode{
    margin-right: 0;
    font-size: 13px;
    padding: 7px 14px;
    background: #f9f9f9;
    border-radius: 4px;
    p{
        display: block;
        margin-bottom: 1px;
        font-size: 12px;
        color: #ACACAC;
        white-space: nowrap;
    }
}

&__priceBox{
    display: flex;
    align-items: center;
    h2{
        margin-right: 15px
    }
    h3{
        color: #ACACAC;
        text-decoration:line-through;
    }
}
&__iconContainer{
    display: flex;
    align-items: center;
    position: relative;
&:hover {
    cursor: pointer;
    .productDetails__promptBlock{
        overflow: hidden;
        opacity:1;
    transition: 0.4s all;
    a{
        border-bottom: 1px dashed #ACACAC;
        &:hover{
            color: red;
        }
    }
}
}
}

&__buttonBlock{
    display: block;
    .whiteButton{
        margin-top: 10px;
    }
}
}
}