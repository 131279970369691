.renderSimilarProduct{
&__mainTitle{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}
&__slider{
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
}
&__block{
    padding-right: 10px;
}
&__contentBox{
    margin: 10px;
}
    &__imgContainer {
        max-height: 200px;
        width: 100%;
        min-width: 200px;
        display: block;
        margin-right: 10px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .btn-slide1.next{
        position: absolute;
        top: 40%;
        right: 30px;
        height: 50px;
        width: 50px;
        transition: 0.3s all;
        outline:none;
        background: rgba(255, 255, 255, 0.8);
        border: none;
        &:hover{
            background: rgba(0,0,0,.5);
            color: #f1f1f1;
        }
        &:active{
            color:#c272ff;
            background: rgba(0,0,0,.9);
        }
    }
    .btn-slide1.prev{
        position: absolute;
        top: 40%;
        left: 30px;
        height: 50px;
        width: 50px;
        transition: 0.3s all;
        outline:none;
        background: rgba(255, 255, 255, 0.8);
        border: none;
        &:hover{
            background: rgba(0,0,0,.5);
            color: #f1f1f1;
        }
        &:active{
            color:#c272ff;
            background: rgba(0,0,0,.9);
        }
    }
}