.videoPortfolioPage{
margin: 20px;

    &__headerDescription{
        max-width: 800px;
        margin: 50px auto;
    }
    &__container{
        // height: 100%;
        width: 100%;
        margin-bottom: 20px;
    }
    &__contentBlock{
        width: 100%;
        padding: 0 10px;
        a{
            text-decoration: none;
        }
        p{
            margin-bottom: 40px;
        }
        .blackButton{
            margin-left: 0;
            border: 1px solid #2e353f;
        }
    }

    @media (max-width: 1000px){
        &__headerDescription{
            max-width: 800px;
            margin: 50px auto;
        }
        &__container{
            // height: 100%;
            border-bottom: 1px solid #2e353f;
            padding-bottom: 10px;
            width: 100%;
            margin-bottom: 20px;
        }
        &__contentBlock{
            width: 100%;
            padding: 0 20px;
            h2{
                margin-top: 20px;
                font-size: 35px;
                text-align: center;
            }
            p{
                margin-top: 40px;
            }
            .blackButton{
                text-align: center;
                margin: 0 auto;
                border: 1px solid #2e353f;
            }
        }
    }
}

.renderVideoYoutube{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &__youTubeContainer{
        min-height: 400px;
    }

    @media(max-width:600px){
        &__youTubeContainer{
            min-height: 300px;
        } 
    }
}