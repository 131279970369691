.benefits{
margin: 50px 20px;
&__titleContainer{
    max-width: 600px;
    margin: 0 auto;
}
h1{
    color: black;
    padding: 20px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
}
&__title{
    font-size: 20px;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #9d9d9d;
}
 &__blockContainer{
    margin: 0 auto;
    background-color: #2e353f;
    padding: 10px;
    height: 100%;
    h2 {
        color: aliceblue;
        text-align: center;
        margin: 20px 0;
        font-weight: 600;
    }
    p{
        color: aliceblue;
        padding: 0 20px;
    }
   
    }
    &__blockContainer.grey{
    background-color: #cdcdcd;
    h2 {
        color: black;
        text-align: center;
        margin: 20px 0;
        font-weight: 600;
    }
    p{
        color: black;
        padding: 0 20px;
    }
    }
}