.portfolio{
&__MainContainerCartBlock{
    max-width: 1100px;
    min-height: 500px;
    margin: 0 auto;

a{
    text-decoration: none;
}
}

   &__buttonBlock{
    display: flex;
    justify-content: flex-end;
    a{
        text-decoration: none;
        &:hover .portfolio__imgAndText{
            &::before{
                content: '';
                z-index: 7;
                background-color: rgba(0,0,0,.2);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        opacity: 1;
    }
}
   } 
   &__buttonBlockInside{
    position: absolute;
    width: 100%;
    top: 60%;
    opacity: 0.9;
margin: 0 auto;
}
&__cartBlock{
    position: relative;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    transition: 0.5s all;

 &__imgAndText{
        height: 100%;
        width: 100%;
        transition: 0.5s all;
    }
     &:hover{ 
    // background-color: rgba(141, 141, 141, 0.6);
    background-color: rgba(141, 141, 141, 0.5);
  
    img{
    transition:all 0.25s;
    // opacity: 0;
    transform: scale(1.1)
}
        }
}
&__blockImg{
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
    transition:all 0.5s;
        
    }
}

&__blockText{
    position: absolute;
    transition: 0.5s all;
    max-height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    z-index: 5;
    // background-color: rgba(141, 141, 141, 0.6);
    height: 100%;

    button{
        position: absolute;
        bottom: 0;
        right: 50%;
    }
}
&__textData{
    margin: 30px 0; 
    color: rgb(73, 73, 73) !important;
    font-size: 10px;
    line-height: 1;
}
&__containerText{
    padding: 20px;
    color: black;
}
&__cartBlock:hover{
.portfolio__textData{
    color: white;
}
    .portfolio__containerText{

        color: white; 
    }
}
@media(max-width: 500px){
    &__buttonBlock{
        margin-right: 25px;
    }
}
}