.comeBackButton{
    &__row{
        display: flex;
    align-items: center;
    justify-content: end;
    padding: 20px;
    }
 &__linkBox{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 0.3s all ;
    cursor: pointer;
    &:hover{
        color: #7448ff;
    }

    svg{
        height: 20px;
        width: 20px;
        margin-right: 10px; 
    }
 }
 @media (max-width: 700px){ 
    &__row{
    padding: 10px;
}
}
} 