.miniModalQuestion{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    z-index: 999999999;
    background-color: rgba(182, 182, 182, 0.6);
    
    &__content{
        padding: 20px;
        border-radius: 4px;
        background-color: #fff;
        width: 400px;
        min-height: 150px;

    }
    &__buttonContainer{
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
} 