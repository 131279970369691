// .containerCabinetComponent__fix.fixedClass{
//     position: fixed;
//     top: 80px;
//     min-width: 350px;
    
// }
.containerCabinetComponent{
    z-index: 9999999;
    padding: 0 40px;
    min-height: 500px;
    max-width: 1300px;    
    margin: 10px auto;
    &__personContainer{
        color: var(--text-color);
    }
    &__LinkText{
        color: var(--text-color);
    }
    &__row{
        display: flex;
        position: relative;
    }
    &__menuIcon{
        display: none;
        margin-top: 35px;
        position: absolute;
        z-index: 11;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
        }
    }
    &__cabinetSidebar{
        width: 30%;
        height: 100vh;
        margin-top: 19px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
        border-right: 1px solid rgb(214, 214, 214);
    }
 
    &__container{
        border-top: 1px solid rgb(214, 214, 214);
        border-bottom: 1px solid rgb(214, 214, 214);
    }
    &__contentBlock{
        width: 70%;
    }
        &__customLinkContainer{
            display: flex;
            align-items: center; 
        }
        &__iconContainer{
            width: 35px;
            height: 35px;
            margin-right: 5px;
            display:flex;
            align-items: center;
            transition: all 0.3s;
            svg{
                font-size: 25px;
                color: var(--text-color);
            }
        }
        &__content{
            padding: 5px;
            align-items: center;
            height: 50px;
            margin: 10px;
            display: flex;
            padding-left: 20px;
            color: black;
            align-items: center;
            text-decoration: none;
        }
         a{
            color: #221f1f;
            text-decoration: none;
        }
        &__content:hover{
            background-color: #00a04633;
            transition: all 0.3s;
            cursor: pointer;
        }
        &__content.active{
            background-color: #00a04633;
        }
        &__content.button{
            font-size: 14px;
            color: #221f1f;
        }
        &__closeIconBlock{
            display: none;
        }
        @media (max-width: 800px){
            &__cabinetSidebar {
                position: fixed;
                width: 250px;
                top: 0;
                background-color: aliceblue;
                margin-top: 0;
                transform: translateX(-120%);
                z-index: 9999999;
            }
            &__closeIconBlock{
                display: block;
            }
            padding: 10px;
            min-height: 500px;
            &__contentBlock{
                width: 100%;
            }
            &__menuIcon{
                display: block;
            }
            &__cabinetSidebar.open{
                transform: translateX(-10px);
            }
            &__fix{
                padding-top: 30px;
            }
        }
        @media (min-width: 800px){
            &__cabinetSidebar {
            display: block;
        }
        
        }
}