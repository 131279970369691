.itemsPickupPoints{

    &__title{
        margin-bottom: 10px;
    }
}

.itemPickupPoints{
    margin-bottom: -1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(236, 236, 236);
    padding: 21px 24px 22px;
    border-radius: 3px;
    cursor: pointer;
    &:hover{
        background-color: #fafafa;
    }
    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgb(236, 236, 236);
        }
    &__contentBox{  
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    &__buttonBox{
        color: rgb(38, 145, 236);
        background-color: rgba(38, 145, 236, 0.35);
        display: flex;
        align-items: center;
        justify-content: space-between; 
        padding: 8px 20px;
        &__text{
            color: white;
        }
    }
    &__imgContainer{
        width: 100px;
        height: 100px;
        max-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 100%;
            width: auto;
        }
    }
}

.itemPickupPoints.currentId{
    background-color: rgba(0, 255, 98, 0.1);
    transition: all 0.5s ease-out;
}