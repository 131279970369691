.titleHeaderComponent{
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    .titleComponent{
        margin-top: 10px;
        margin-left: 30px;
        &__title{
            text-transform:uppercase;
            color: #349a87;
        }
        &__descriptionBlock{ 
            position: relative;
            display: flex;
            justify-content: flex-start;
        }
        &__grey{
            color: #a5a5a5;
            text-transform:uppercase;
        }
            h2{
                text-transform:uppercase;
                position: relative;
                font-size: 23px;
                &::before{
                    content: '';
                    position: absolute;
                    background-color: red;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    top: 10px;
                    left: -25px;
                }
            }
            @media (max-width: 400px){ 
                h6{
                    font-size: 10px;
                }
                h2{
                    font-size: 15px;
                    &::before{
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        top: 3px;
                        left: -15px;
                    }
                }
            }
    }
    @media (max-width: 400px){ 
        display: block;
        flex-wrap: wrap;
        max-width: 100%;
        margin: 0 auto;
    }
}