// .orderStatus{
//     // width:5px ;
//     background: #777;
//     font-size: 12px;
// }

// .orderStatus:last-child{
//     width: 0;
// }
// .orderStatus .point{
//     width: 15px;
//     height: 15px;
//     position: absolute;
//     border-radius: 8px;
//     background-color: #777;
//     // top: -5px;
// }
// .orderStatus .point.active{
//     background-color: #26A541;
// }
// .orderInfo{
//     // height: 60px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     // position: absolute;
//     // top: -26px;
//     transform: translateX(-50%);
// }

// .title{
//     font-size: 12px;
//     color: #797878;
// }
// .value{
//     font-size: 14px;
//     color: black;


// }


.orderStatus{
    margin: 20 auto;
    &__row{
        text-align: center;
        margin: 10px auto;
        max-width: 300px;
    }
    &__item{
        display: flex;
        align-items: center;
        justify-content: center;
        }
    &__name{
        position: relative;
        font-size: 17px;
        margin-bottom: 10px;
    }
    &__name.active{
        font-size: 17px;
        margin-bottom: 10px;
        color: rgb(41, 207, 0);
    }
    &__time{
        font-size: 13px;
        top: -10px;
        right: -50px;
        position: absolute;
    }
    &__time.active{
        color: rgb(255, 255, 255);
    }
}
