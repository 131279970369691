.hrefButtonBox{
        position: absolute;
        display: none;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999999999;
        &__buttonsBox{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            button{
                padding: 10px 20px;
                background: #349a87;
                color: white;
                margin: 10px;
                outline: none;
                border: none;
                &:hover{
                    background: rgb(67, 197, 173);
                }
            }
        }
    }
    .hrefButtonBox.active{
    display: block;
}