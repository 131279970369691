@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  /* Banner */
  --banner-outer-height: 70px;
  --banner-inner-height: 70px;
  --banner-height-difference: calc(
    var(--banner-outer-height) - var(--banner-inner-height)
  );
  --banner-bg: #2e353f;
  
  /* Header */
  --header-outer-height: 90px;
  --header-inner-height: 70px;
  --header-bg: #FFF;
}

body {   
  font-family: "DM Sans", sans-serif;
  background-color: #f2f5f7;
  line-height: 1.5;
// min-height: 300vh;
  position: relative;
}


a {
  color: inherit;
}

.responsive-wrapper {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

/* Sticky banner */
.banner-outer {
  /* Make it stick */
  height: var(--banner-outer-height);
  position: sticky;
  top: calc(var(--banner-height-difference) * -1); /* Multiply by -1 to get a negative value */
  display: flex;
  align-items: center;
  
  /* Other */ 
  background-color: var(--banner-bg);
  z-index: 999999;
}

.banner-inner {
  /* Make it stick */
  height: var(--banner-inner-height);
  position: sticky;
  top: 0;
  
  /* Other */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.25;
}

.header-outer {
  height: var(--background-color);
  display: flex;
  align-items: center;
  background-color: var(--ba); 
  box-shadow: 0 2px 10px 0 rgba(0,0,0, .1);
}

.header-inner {
  max-width: 1200px;
  width: 90%;
  height: var(--header-inner-height); 
  display: flex;
  align-items: center;
  justify-content: space-between;
}


/* Styling of other elements */
.header-logo img {
  display: block;
  height: calc(var(--header-inner-height) - 30px);
  filter: var(--svgMainIcon);
}

.header-navigation {
  display: flex;
  flex-wrap: wrap;
}

.header-navigation a,
.header-navigation button {
  font-size: 1.125rem;
  color: inherit;
  margin-left: 1.75rem;
  position: relative;
  font-weight: 500;
}

.header-navigation a {
  display: none;
  font-size: 1.125rem;
  color: inherit;
  text-decoration: none;
}

.header-navigation button {
  border: 0;
  background-color: transparent;
  padding: 0;
}

.header-navigation a:hover:after,
.header-navigation button:hover:after {
  transform: scalex(1);
}

.header-navigation a:after,
.header-navigation button:after {
  transition: 0.25s ease;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: currentcolor;
  transform: scalex(0);
  position: absolute;
  bottom: -2px;
  left: 0;
}

.main {
  margin-top: 3rem;
}

@media (min-width: 800px) {
  .header-navigation a {
    display: inline-block;
  }

  .header-navgitaion button {
    display: none;
  }
}