.renderMiniModalCabinet{
        width: 600px;
        position: relative;
        padding: 10px;
    &__title{
            border-bottom: 1px solid #cacaca;
            font-size: 24px;
    }
    &__description{
        padding: 10px;
        p{
            font-size: 14px;
            margin: 15px 0;
        }
    }
    &__contentBox{
        padding: 10px;

    }
    &__buttonBox{
        display: flex;
        justify-content: start;
        align-items: center;
    }
    &__closeButtonText{
        color: #3e77aa;
        text-transform: uppercase;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
    &__buttonPartnerPageHref{
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    // &__contentBox{

    // }
}