/* Material Modal */
.modalFixedBg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000; 
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999595922;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalContainer {
    min-width: 350px;
    max-width: 500px;
    padding: 20px;
    min-height: 500px;
    max-height: 98vh;
    background: #5b5b5b;
    border-radius: 4px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 auto;
  }

  .modalClose {
    position: absolute;
    cursor: pointer;
    color: #fff;
    right: 10px;
    font-size: 35px;
    svg{
      width: 25px;
      height: 25px;

    }
}
.modalFixedBg-callMe {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000; 
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  &__modalContainer {
    padding: 10px;
    max-width: 500px;
    min-height: 450px;
    background: #fff;
    border-radius: 4px;
    margin: 0 auto;
  }
  &__modalClose {
    position: absolute;
    cursor: pointer;
    color: rgb(0, 0, 0);
    right: 10px;
    font-size: 30px;
  }
}



/* Material Input */
  .materialInput {
      margin: 0 auto;
    width: 100%;
    min-width: 200px;
    height: 30px;
    border-bottom: 1px solid #979797;
    position: relative;
    /* background-color: red; */
    margin-bottom: 30px;

    &__iconBlockPassword{
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      z-index: 2;
      cursor: pointer;
      &:hover{
        svg{
          color: #00000067;
        }
      }
    }
  }
  .materialInput input {
    width: 100%;
    flex: 1;
    outline: none;
    border: none;
    z-index: 1;
    background: transparent;
    line-height: 26px;
      // color: #cacaca;

  }
  .materialInput .label {
    position: absolute;
    line-height: 30px;
    z-index: 0;
    font-size: 12px;
    letter-spacing: 1px;
    transition: all 0.2s ease-in-out;
  }
  .materialInput .label.focus {
    top: -22px !important;
  }

   
.materialButton {
    text-transform: uppercase;
    text-align: center;
    min-width: 150px;
    height: 45px;
    margin: 15px;
    color: #6d6d6d;
    background-color: #edf3f3;
    border: 0;
    box-shadow: inset 0 0 0 1px #cfcfcf;
    transition: all 0.3s;
    &:hover {
        background-color: #212529;
        color: #ffffff;
    }
    &:active {
        outline: none !important
    }
    &:disabled{
    background-color: #edf3f34d;
        &:hover{
          color: red;
        }
    }
  }

.materialButtonOval {
    position: relative;
    text-transform: uppercase;
    text-align: center;
    width: 170px;
    height: 30px;
    margin: 0 0 5px 20px;
    color: #6d6d6d;
    border-radius: 25px;
    background-color: #edf3f3;
    border: 1px solid #edf3f3;
    transition: all 0.3s ease;
    &:hover {
        transition: all 0.3s ease-in-out;
        background-color: #349a87;
        color: #ffffff;
    }
    &:active {
        outline: none !important
    }
    svg{
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }
  

  .materialInput-callMe{
      margin: 0 auto;
    width: 85%;
    min-width: 300px;
    height: 30px;
    border-bottom: 2px solid #cacaca;
    position: relative;
    /* background-color: red; */
    margin-bottom: 35px;
    input {
      width: 100%;
    flex: 1;
    outline: none;
    border: none;
    z-index: 1;
    background: transparent;
    line-height: 30px;
    color: rgb(0, 0, 0);

  }
  .label {
    position: absolute;
    line-height: 30px;
    color: #6d4bf5;
    z-index: 0;
    font-size: 12px;
    letter-spacing: 1px;
    transition: all 0.2s ease-in-out;
  }
  .label.focus {
    top: -20px !important;
  }
  
  }
  
