.masonryGrid {
  display: flex;
  width: auto;
  margin: 0 auto;
  max-width: 98%;
}

.masonryGridColumn {
  padding-left: 10px;
  background-clip: padding-box;
}

.masonryGridColumn img {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
