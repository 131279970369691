.renderHrefContent{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
    background: rgba(0,0,0,0.2);
    &__containerHref{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    a{
        text-decoration: none;
    }
    &__mainBox{
        margin: 0 auto;
        text-align: center;
       
        h3{
            color: white;
            margin-bottom: 10px;
        }
    }
    .orderCatalog-white{
        margin: 0 auto;
    }
}