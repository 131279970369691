.mailingLists{


    &__subscriptions{

        &__item{
            padding: 10px;
            border: 1px solid rgba(128, 128, 128, 0.377);
            margin-bottom: 10px;
            display: flex;
            // align-items: center;
            justify-content: space-between;
        }
        &__imgBox{
            height: 80px;
            width: 100px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                height: 100%;
                width: auto;
             
            }
        }
        &__contentBox{
            height: 100px; 
            min-width: 150px;
            position: relative;

        }
        &__title{
            font-weight: 600;
            max-width: 150px;
        }
    }
}