.renderDesiresProduct{
    max-height: 1000px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__mainContainer{
        border: 1px solid rgb(232, 232, 232);
        padding: 16px;
        margin: 5px auto;
        position: relative;
        &:hover{
            box-shadow: 0 4px 5px 0 #d8d8d8; 
        }
    }
    &__imgContainer{
        height: 170px;
        width: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        img{
            max-width: 170px;
            max-height: 170px;
            width: auto;
            height: auto;
            transition: all 0.3s;
            // max-height: 200px;
        }
     
    }
    &__removingSingleElement{
        position: absolute;
        cursor: pointer;
        right: -10px;
        top: -10px;
        svg{
            margin: 5px;
            font-size: 22px;
        }
    &:hover{
        svg{
            color: red;
        }
    }

    }
    &__checkboxContainer{
        position: absolute;
        top: -12px;
        left: -10px;
    }
    &__imgNameBox{
        position: relative;
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    &__imgTopText{
        top: 5px;
        left: 10px;
        position: absolute;
        padding: 5px 10px;
        border-radius: 20px;
        background-color: rgb(255, 169, 0);
        color: rgb(255, 255, 255);
    }
}