.desiresPage{
    border: 1px solid rgb(214, 214, 214);

    h2{
        display: block;
        padding: 10px;
    }
    &__mainContainer{
        padding: 5px;
    }
    &__desiresProduct{
        min-height: 200px;
     
        display: flex;
        align-items: center;
        justify-content: center;
        p{  
            font-size: 20px;
            text-align: center;

        }
    }
    &__emptyProd{
        text-align: 17px;
        padding: 20px;
        text-align: center;
    }
&__desiresNameAndMoreRow{
    border-bottom: 1px solid rgb(214, 214, 214);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    &__nameListDesires{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
}
&__chooseAll, &__delete{
    margin-right: 25px;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
}
&__chooseAll{
    color: #3e77aa;
    &:hover{
        color: red;
    }
}
&__delete{
    color: #a6a5a5;
    position: relative;
    svg{
        position: absolute;
        left: -10px;
    }
    &:hover{
        color: red;
    }
}
&__moreIconContainer{
    margin-right: 10px;
    position: relative;
    svg{
        font-size: 30px;
        color: #3e77aa;
        &:hover{
            color: red;
        }
    }
}
&__defaultNameGrayText{
    color: #a6a5a5;
    font-size: 20px;
}
&__headerMenu{
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

}
&__settingContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
 }
&__delete{
    position:relative;
    svg{
        position: absolute;
    }
}
@media (max-width: 600px){
    &__nameListDesires{
        h2{
            margin: 0 auto;
            font-size: 20px;
        }
}
    &__defaultNameGrayText{
        margin: 0 auto;
        margin: 10px;
        font-size: 18px;
    }
}
} 