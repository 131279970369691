.portfolioPage{
    margin: 20px;
    min-height: 500px;
    &::before{
        content: '';
        position: absolute;
        min-width: 40%;
        // max-width: 600px;
        background-color: black;
        opacity: 0.1;
        height: 500px;
        right: 0;
    }
   &__titleContainer{
       margin: 0 auto;
       max-width: 1300px;
       margin-bottom: 40px;
       display: flex;
       justify-content: space-between;
    &__leftTitleBlock{
        max-width: 1000px;
        p{
            color: rgb(146, 146, 146);
        }
    }
    &__rightButtonBlock{
        height: 100%;
        width: 100%;
       .purpleButton{
           bottom: 20px;
           right: 20px;
           min-width: 300px;
           margin: 0 auto;
        }
    }
   } 

.renderPortfolio{
max-width: 1300px;
margin: 0 auto;
    &__imgContainer{
        width: 100%;
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
        &:hover{
            background: rgba(0,0,0,.5);
        }
        img{
            width: 100%;
            height: 100%;
        }
           &:hover .renderPortfolio__blockText {
            opacity: 1;
            transform: translateY(0);
        overflow: hidden;


            &__city,
            &__name{
                transform: translateY(0);
                transition: 0.5s all;
                opacity: 1;
            }
          }
       
    }
    &__blockText{
            position: absolute;
            transform: translateY(10px);
            transition: 0.5s all;
            max-height: 100%;
            top: 0; 
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,.5);
            opacity: 0;
            color: white;
&__city{
    position:absolute;
    bottom:35px;
    left: 20px;
    transform: translateY(10px);
    opacity: 0;
    text-transform: uppercase;
}
&__name{
    text-transform: uppercase;
    position:absolute;
    font-size: 20px;
    bottom:10px;
    left: 20px;
    transform: translateY(10px);
    opacity: 0;
}
   }
   @media(max-width: 700px){
    &__blockText{
        position: absolute;
        transform: translateY(10px);
        transition: 0.5s all;
        max-height: 100%;
        top: 0; 
        left: 0;
        right: 0;
        bottom: 0;
        background: none;
        opacity: 1;
        color: white;
        &__city{
            position:absolute;
            bottom:35px;
            left: 20px;
            transform: translateY(10px);
            opacity: 1; 
            text-transform: uppercase;
        }
        &__name{
            text-transform: uppercase;
            position:absolute;
            margin: 0 auto;
            text-align: center;
            font-size: 25px;
            bottom:10px;
            left: 20px;
            transform: translateY(10px);
            opacity: 1;
        }
    }
   }
}
@media(max-width: 700px){
    &__imgContainer{

    }
    &__titleContainer{
        margin: 0 auto;
        margin-bottom: 40px;
        display: block;
     &__leftTitleBlock{
         max-width: 1000px;
         p{
             color: rgb(146, 146, 146);
         }
     }
     &__rightButtonBlock{
         height: 100%;
         width: 100%;
        .purpleButton{
            bottom: 20px;
            right: 20px;
            min-width: 300px;
            margin: 0 auto;
         }
     }
    } 

}
}
