.renderMoreMenu{
    margin-right: 10px;
    z-index: 100;
    position: relative;
    &__mainContainer{
        min-width: 150px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
        position: absolute;
        right: 28px;
        top: 3px;
        z-index: 6;
    }
    ul{
        margin: 0;
        padding: 0;
    }
    li{
        list-style-type: none;
        padding:  16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg{
            font-size: 25px;
            color: #4c4c4c;
        }
        &:hover{
            background-color: #e9e9e9;
            color: #349a87;
            svg{
                color: #349a87;
            }
        }
    }
    &__renameIconBox.active{
        svg{
            color: red;
        }
    }
    &__rename{
    font-size: 14px;
    text-align: left;
    width: 150px;
    margin-left: 5px;
    }
    svg{
        cursor: pointer;
        font-size: 30px;
        color: #3e77aa;
        &:hover{
            color: red;
        }
    }
}