.carouselComponent{
    max-width: 95%;
    min-height: 300px;
    margin: 0 auto;
    // min-height: 350px;
    &__sameProductBlock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 0, 0, 0.8);
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeOut 5s forwards;
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    &__contentBox{
        display: flex;
        justify-content: space-between;
    }
    &__cartButton{
        margin-right: 5px;

        svg{
            color: #7448ff;
        }
        &:hover{
            
        }
    }
    &__row{
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    &__mainContainer{
        cursor: pointer;
        height: 300px;
        position: relative;
        &:hover{
            background-color: rgba(0,0,0,0.1);
        }
    }
    &__arrowButtonContainer{
         position: relative;
        width: 100vw;
    }
    &__arrowBtnLeft, &__arrowBtnRight {
        z-index: 9;
        position: absolute;
        cursor: pointer;
        transform: translateY(100%);
        background-color: transparent;
        border: none;
        color: #000;
        font-size: 2rem;
        transition: 0.3s all;
        svg{
            width: 70px;
            height: 70px;
            color: rgb(199, 199, 199);
        }
        &:hover{
            background-color: rgba(255, 255, 255, 0.2);

            svg{
                color: #349a87;
            }
        }
      }
    &__arrowBtnLeft{
        left: 30px;
      }
    &__arrowBtnRight {
        right: 30px;
      }

    &__imgBox{
        height: 200px;
        max-width: 500px;
        margin: 5px;
        active-img {
            opacity: 0.8;
        }
        img{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: auto;
        }
    }
    &__contentFull{
       .cartProductButton{
        right: 10px;
        bottom: 20px;
        position: absolute;
       } 
    }
}