.renderFullImgModal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1200px;
  min-height: 100px;
  margin-bottom: 0;
  z-index: 1;

  &__contentRow {
    height: 100vh;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;
    max-height: 100%;
  }

  &__indexNumberContainer {
    position: absolute;
    padding: 15px 50px;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    margin: 5px;
    color: rgba(255, 255, 255, 0.87);
    font-size: 20px;
    z-index: 999;
  }

  &__imgContainers {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    position: absolute;
    max-height: 100vh;
  }

  &__imgContainer {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    transition: opacity ease-in-out 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      height: auto;
      max-width: 100%;
      max-height: 100%;
      min-height: 650px;
      object-fit: cover;
      position: relative;
    }
  }

  .active-anim {
    opacity: 1;
  }

  .btn-slide1 {
    width: 60px;
    height: 60px;
    background: #f1f1f1;
    border: 1px solid rgba(255, 255, 255, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    transition: all 0.4s;
    cursor: pointer;

    &:hover {
      background-color: rgb(255, 255, 255);
      opacity: 1;
    }
  }

  .prev {
    top: 50%;
    transform: translateY(-50%);
    left: 13%;
    height: 500px;
  }

  .next {
    top: 50%;
    transform: translateY(-50%);
    right: 13%;
  }

  @media (max-width: 1000px) {
    &__imgContainer {
      max-width: 100%;

      img {
        margin: 0 auto;
        min-height: 200px;
      }
    }
  }

  @media (max-width: 600px) {
    &__imgContainer {
      display: flex;
      width: 100%;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
        position: relative;
        object-fit: cover;
      }
    }
  }
}
