
.renderDetailsOrderProductModal{
    width: 100%;
    &__mainContent{
        
        padding: 20px;
    }
    &__additionalItems{
        max-width: 500;
        border-top: 1px solid #000;
        margin-top: 20px;
        h4{
            text-align: center;
        }
        h5{
            text-align: center;
            margin: 15px 0;
            color: red;
        }

    } 
    &__additionalItem, &__additionalItemHeader{ 
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__additionalItem{
        border-top: 1px solid #cecece;
        padding: 10px;
    }
    &__additionalTitle{

    }
    &__additionalDescription{

    }
    &__additionalTitleHeader,&__additionalDescriptionHeader{
        color: #464646;
    }
 
}