.fixedButtons {
    position: fixed;
    
    z-index: 900000;
    display: flex;
    flex-direction: column; 
    align-items: flex-end; 
    bottom: 80px; 
    right: 10px; 
  
    &__items {
      display: flex;
      width: 200px;
      align-items: center;
      justify-content: center;
    }
  
    &__item {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      background: rgba(241, 241, 241, 0.3);
      border: 2px solid rgba(69, 69, 69, 0.5);
      margin-top: 5px; /* Добавляем отступ между кнопками */
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  