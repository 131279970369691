.searchModalContainer{
    position: fixed;
    top: 0;
    margin-top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 12;
  
    &__moreIcon{
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 9999999999;
    }
    h6{
        color: #349a87;
    }
    &__imgContainerTitle, &__nameTitle,  &__nameTitleMini{
        font-size: 18px;
        font-weight: 600;
        color: #349a87;
    }
    &__nameTitle{
        width: 200px;
    }
    &__imgContainerTitle{
        width: 150px;
    }
    
    &__modal{
        background-color: rgba(224, 224, 224, 0.95);
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        max-height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        h1{
            margin: 10px auto;
            color: rgb(28, 28, 28);
        }
    }
    
    &__productBox{
        width: 100%;
        height: 100%;  
        position: relative;
        padding: 15px; 
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        &:hover{
            background-color: rgb(187, 187, 187);
        }
    }

    &__rowProduct{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
    
    &__rowProduct.title{
        padding-bottom: 20px;
        margin: 0 10px;
        max-width: 800px;
        border-bottom: 1px solid #349a87;
    }
   
    &__name, &__category{
        display: block;
        font-size: 18px;
        text-align: center;
        width: 200px; 
    }
    
    &__productMainContainer{
        max-width: 800px;
        margin: 0 auto;
    }
    
    &__imgContainer{
        height: 100px;
        width: 150px;
        
        img{
            height: 100%;
            overflow: hidden;
            max-width: 100%;
            width: auto;
        }
    }
    &__flexContainer, &__nameTitleMini{
        display: none;
    }
    @media(max-width: 800px){
        margin-top: 115px;
    }
    @media(max-width: 600px){
        &__modal{
            h3{
                margin-right: 30px;
            }
        }
        &__rowProduct{
            position: relative;
        }
        &__flexContainer{
            display: block;
            position: absolute;
            right: -25px;
            .searchModalContainer__descBox, .searchModalContainer__category {
                display: block;
            }
        }
        &__descBox, &__category, &__nameTitle{
        display: none;
        }
        &__nameTitleMini{
            display: block;
        }
        &__imgContainer{
            height: 100px;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-width: 100%;
                max-width: 200px;
                display: block;
                margin: 0 auto; 
            }
        }
    }
}
