.partnerPage{

    &__newPartnerBlock{
        background-color: #349a87;
        border: 1px solid #ababab;
        max-width: 500px;
        min-height: 200px;
        margin: 0 auto;
        transition:all 0.5s;
        &:hover{
            border: 1px solid #474747;
            border-radius: 10px;
            .materialButton{
                transform: translateY(-10px);
            }
        }
    }
    &__contentBlock{
        text-align: center;
        padding: 10px;
        h2{
           border-bottom: 1px solid aliceblue; 
           padding: 10px 0;
           margin: 0 auto;
           font-size: 17px;
           color: aliceblue;
           text-transform: uppercase;
        }
        p{
           color: aliceblue;
           font-size: 15px;
            
        }


    }
    &__mainContainer{
        margin-top: 20px;
    }
    &__noneContent{
        text-align: center;
    }
}