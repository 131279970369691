
.loader{
&__loaderContainer, &__showContentBox{
    display: flex;
  justify-content: center;
  align-items: center;
  height: calc(500px - 60px);
  font-size: 20px;

  p{
    margin-left: 20px;
    display: block;
  }
}
&__title{
  font-size: 25px;
  text-align: center;
  margin: 10px auto;
}
&__lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #f1c40f ;
    border-color: #555244  transparent #555244  transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
    }