.callMe{
    position: fixed;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    bottom: 20px;
    right: 5px;
    background: linear-gradient(to right, #349a87, #7dc4dd, #e9e9e9);
    transition: 0.5s;
    z-index: 99999;

&:hover{
    background-color: #393838;
    transition: 0.5s;
}
svg{
    margin:auto;
    display: block;
    height: 40px;
    width: 40px;
    color: rgb(255, 255, 255);
    transform: translateY(12px);
  
}
}
.callMeBlock{
    height: 140px;
    width: 350px;
    border-radius: 30px;
    bottom: 20px;
    right: 20px;
    position: fixed;
    background: linear-gradient(to right, #349a87, #7dc4dd, #e9e9e9);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.71); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.71);
    z-index: 999;

&__contentBlock{
    padding: 10px;
    h4{
        margin-left: 15px;
    }
    @media (max-width: 600px){
        padding: 10px;
        h4{
        font-size: 14px;
        font-weight: 600;
        }
    
    }
}
&__closeButton{
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    right: 0;
    top: -35px;
    border-radius: 50%;
    background: linear-gradient(to right,#349a87, #7dc4dd, #e9e9e9);
svg{
    color: rgb(61, 61, 61);
    height: 24px;
    width: 24px;
    &:hover{
        color: red;
    }
}
}
}