.renderDeliveryPG{
    position: relative;
    display: flex;
 
    &__buttonBlock{
        margin: 10px;
    }
    &__blockTitles{
        padding: 10px 20px;
        font-size: 18px;
        border-top: 2px solid rgb(255, 255, 255);
        top: -20px;
        color: rgb(113, 113, 113);
        position: relative;
        cursor: pointer;
    }
    &__blockTitles.active{
        position: relative;
        top: -20px;
        border-top: 2px solid black;
        font-weight: 600;
        color: rgb(0, 0, 0);

    }
        &__container{
        max-height: 330px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        top: 30px;
        left: 0;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: opacity ease-in-out 0.4s;

    }
    
    .active-block{
        opacity: 1;
        visibility: visible;

    }
&__itemsText{
    font-size: 17px;
    position: relative;
    text-indent: 20px;
    &::before{
        content: '';
        height: 2px;
        top: 13px;
        left: 0px;
        width: 13px;
        background: black;
        position: absolute;

    }
}
}