.renderStatusArray{
    &__content{
        max-width: 300px;
        margin: 0 auto;
    }
    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d6d6d6;
        padding: 10px 0;
        position: relative;
    }
    &__date{
        margin-right: 10px;
    }
    &__iconContainer{
        position: absolute;
        right: -15px;
        
        svg{
            color: green;
        }
    }
}