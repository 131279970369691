.basicModal{
    max-width: 95%;
    &__closeIconRow{
        position: relative;
    }
    &__iconClose{
        position: absolute;
        right: 5px;
        top: 5px;
    }
}