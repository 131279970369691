.warranty{
position: absolute;
&__component{
    // border-bottom: 1px solid gray;
    padding: 5px;
    &:hover{
        background-color: rgb(238, 238, 238);
        img{
        transform: scale(1.15);
    }
    }
}   
    &__titleRow{
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 5px;
    }
    &__title{
        font-weight: 600;
        font-size: 17px;
        margin-left: 10px;
    }
    &__imgBox{
        width: 40px;
        height: 30px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            height: auto;
            transition: all 0.3s;
        }
    }
}