.orderMenuSearchSort{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &__inputContainer{
        position: relative;
        max-width: 500px;
        height: 45px;

     input[type=text] {
        color: rgb(24, 24, 24);  
         font-size: 15px;
        padding-left: 35px;
          }
    }
    &__searchIcon{
        position: absolute;
        top: 7px;
        left: 5px;
        svg{
            font-size: 30px;
            color: rgb(110, 110, 110);  
        }
    }
    &__search{
            width: 100%;
            height: 100%;
        }
    @media(max-width: 700px){
        &__inputContainer{
        max-width: 100%;
        margin-left: 10px;
        margin-bottom: 20px;
    }
}
}