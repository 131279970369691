.menuCategoryList__blur{
    margin: 0 auto;
    position: fixed;
    // -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.21); 
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.3);
   
}

.menuCategoryList{
    position: absolute;
    top: 85px;
    left: 20px;
    width: 300px;
    border-radius: 5px;
    min-height: 500px;
    background: rgb(251, 251, 251);

    &__listChildCategory{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        &:hover{
            color: rgb(255, 0, 0);
            // background-color: rgb(220, 205, 205);
        }
    svg{
        height: 11px;
        width: 11px;
    }
    }
    &__text{
        justify-content: left;
        text-align: left;
    }
    &__childrenBlock{ 
    position: absolute;
    padding: 10px 0;
     box-shadow: 0 2px 10px 0 rgba(154, 154, 154, 0.4);
     background: rgb(251, 251, 251);
    min-height: 500px;
    display: none;
    width: 300px;
    top: 0;
    border-radius: 5px;
    left: 295px;
    }
    &__childrenBlock.active{
    display: block;
    }

    &__list{
        padding: 10px 0;
        font-size: 14px;
        color: #3e77aa;
    }
    &__row, .newRow{
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        &:hover{
            // background-color: rgb(220, 205, 205);
            color: #ff4747;
            cursor: pointer;
        }
        svg{
            height: 11px;
            width: 11px;
        }
        
    }
    .newRow{
        transition: all 0.1s;
        svg{
            height: 20px;
            width: 20px;
        }
    }
    &__row.active{
        // background-color: rgb(220, 205, 205);
        color: #ff4747;

    }
    &__imgContainer{
        width: 20px;
        height: 20px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @media(max-width: 800px){
        &__childrenBlock{ 
            border-top: 1px solid rgba(154, 154, 154, 0.4);
            top: 150px;
            left: 0;
            }
            &__childrenBlock.active{
            display: block;
            }
    }
    @media(max-width: 500px){
    width: 100%;
    left: 0;
        &__childrenBlock{
            width: 100%;
             overflow-y: auto;
             bottom: 0;
        }
    }
}