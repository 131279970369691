.createUserModal{
    width: 100%;
    margin: 0 auto;
    min-height: 300px;
    background-color: #fff;
    border-radius: 5px;
    h2{
        margin: 20px auto;
    }
    &__rowContent{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    &__emptyButBox{
        display: flex;
        justify-content: center;
    }
    &__loginInputContainer{
        // padding: 20px;
        position: relative;
        height: 100%;

        .materialInput{
            min-width: 260px;
        }
        
        .opacityButtonIcon{
            margin-top: 15px;
        }

        &::before{
            position: absolute;
            content: "";
            width: 100%;
            left: 0;
            bottom: -10px;
            height: 1px;
            background-color: #c1c1c1;
        }
        &::after{
            position: absolute;
            content: "или";
            left: 45%;
            bottom: -22px;
            color: #c1c1c1;
            width: 20px;
            height: 20px;
            background-color: #ffffff;
        }
    }
   
    &__loginButtonContainer{
        margin: 5px 0;
        text-align: center;
        max-width: 250px;
    h3{
        color: #747474;
       display: flex;
       justify-content: center;
        margin: 20px auto;
        font-size: 15px;
        margin-bottom: 20px;
        }
    }

    @media(max-width: 650px){
        width: 100%;
        h2{
            margin-bottom: 20px;
        }
        &__rowContent{
            display: block;
            position: relative;
        }
        &__close{
            position: absolute;
            top: 0;
            right: 0;
        }
       
        &__loginInputContainer{
          
            &::before, &::after{
                display: none;
            }
        }
       
        &__loginButtonContainer{
            margin: 0 auto;
            h3{
      display: none;
            }
        }
        &__buttonBox{
     
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
}