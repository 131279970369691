.mainImageHeaderContainer{
    height: 100%;
    width: 100%;
    position: relative;
    font-family: 'Kdam Thmor Pro', sans-serif;
    font-family: 'PT Sans', sans-serif;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        background-color: rgba(0,0,0,.5);
    }
video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mainImageHeader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // display: block;
    h1{
        color: white;
    }
    &__headerTitle{
        position: relative;
        margin-top: 30px;
        text-align: center;
        color: rgb(255, 255, 255);
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
        font-family: Montserrat;

    }
    &__contentContainer{
        position: relative;
        top: 30%;
    }

    &__contentBlock{
        color: aliceblue;
        margin: 50px 0;
        padding: 0 40px;
        width: 100%;
    }
    &__blockText{
        margin: 0 auto;
        max-width: 400px;
    }
    &__blockButton{
        text-align: center;
        text-decoration: none;
        margin: 30px 0;
        .orderCatalog-white{
        margin: 0 auto;
            text-decoration: none;
        }
    }

}    
@media (max-width: 700px){ 
video{
    width: 100%;
    height: 100%;
    min-height: 600px;
    object-fit: cover;
}
.mainImageHeader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // display: block;

    &__headerTitle{
        position: relative;
        margin-top: 30px;
        text-align: center;
        color: rgba(255, 255, 255, 0.481);
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
        font-family: Montserrat;

    }
    &__contentContainer{
        position: relative;
        top: 10%;
    }

    &__contentBlock{
        color: aliceblue;
        margin: 50px 0;
        padding: 0 40px;
        width: 100%;
    }
    &__blockText{
        margin: 0 auto;
        max-width: 400px;
    }
}
}
@media (max-width: 500px){ 
h1{
    font-size: 20px;
    margin-top: 30px;
}
h3{

    font-size: 17px;
}
&__blockText{
    margin-top: 30px;

}
}
}