.containerPage{
    position: relative;
    &__childrenContent{
        min-height: 800px;
        padding-top: 90px;
        background-color: rgb(231, 231, 231);
    }
    &__mainContentBox{
        padding-top: 50px;
        margin: 0 auto;

    }
    &__title{
        text-shadow: 1px 1px 1px rgb(105, 17, 17);
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.4;
        font-family: Montserrat;
        text-align: center;
    }
    &__description{
        margin: 0 auto;
        max-width: 1000px;
        text-align: center;
        transition: all 0.3s;
        letter-spacing: 1.2px;
        font-size: 20px;
        padding: 10px 30px;
        font-weight: 600;
        font-family: Montserrat;
    }
   &__buttonText{
    padding: 15px 0;
    width: 250px;
    margin: 0 auto;
   }
   &__goBack{
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    margin: 20px 0;
    &:hover{
        color: red;
    }
   }
   @media(max-width: 500px){
    &__description{
        padding: 5px;
        font-size: 16px;
        
    }
   }
}

