.renderNavMenu{
    background-color: #ffffff;
    margin-bottom: 32px;
    position: relative;
    &__renderProductContainer{
        padding: 0 50px;
    }
    &__row{
    display: flex;
    margin-left: 50px;
    flex-direction: row;
    max-width: 1280px;
    overflow: auto;
    align-items: center;
    }

    &__content{
        margin-right: 16px;
        a{
            color: #3e77aa;
            cursor: pointer;
            text-decoration: none;
            display: inline-block;
            &:hover{
            color: #3e77aa;
                
            }
        }
    }
    &__link{
        font-size: 16px;
        height: 40px;
        line-height: 30px;
        padding-left: 8px;
        padding-right: 8px;
        white-space: nowrap;
        cursor: pointer;

        &:hover{
            box-shadow: inset 0 2px 0 0 #00a046;
            color: #00a046;
        }
    }
    &__link.active{
        box-shadow: inset 0 2px 0 0 #00a046;
        color: #00a046;
    }

    @media(max-width: 600px){
        width: 100%;
        margin: 5px;
        &__row{
        margin-left: 0;
        margin-right: 0;
    }
        &__renderProductContainer{
            padding: 0 10px;
        }
    }
    }
