.titleIndex{
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;
        margin: 0 auto;
    }
    &__leftContentBox{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__text{
        margin-left: 5px;
    
    }
    &__index{
        width: 25px;
        height: 25px;
        background: #d8d8d8;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        }
}