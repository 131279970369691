
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=PT+Sans&display=swap');

.productList{
    padding: 20px 0;
    position: relative;
    overflow: hidden;
    a{
        text-decoration: none;

    }
    &__container{
        max-width: 1300px;
        margin: 0 auto;
    }
    &__imageBlock{
        color: #8b009a;
    }
    &__containerIcon{
        text-align: center;   
    }
        svg{
            color: rgb(186, 186, 186);
            height: 70px;
            width: 70px;
            transition: all 0.5s;
        }
    
    &__text{
        text-align:center;
        margin: 5px auto;
        font-size: 15px;
        font-weight: 600;
        transition: 0.8s all;
        text-transform: uppercase;
        height: 23px;
        overflow: hidden;
        width: 100%;
    }
    &__containerMainCategory{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto;

    }
 &__mainCategory{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dbdbdb;
        max-width: 300px;
        min-width: 200px;
        font-weight: 600px;
        height: 35px;
        color: #3f3f3f;
        cursor: pointer;
        margin: 10px;
        padding: 10px;
        transition: 0.3s all;
        &:hover{
        background: #3f3f3f;
        color: white;
        }
    }
    &__mainCategory.active{
        background: #3f3f3f;
        color: white;
    }

        &__categoryItemContainer{
           cursor: pointer;
           display: none;
           width: 100%;
           margin: 0 auto;
           transition: 0.3s all;

           &:active{
            color: red;

           }
        }
        
        &__categoryItemContainer.active{
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            gap: 20px 0; 
            align-items: center; 
        }
        &__imgContainer{
            height: 60px;
        img{
            filter: var(--svgCategoryIcon);
            height: 100%;
            width: auto;
            z-index: 1;
        }
    }
        &__categoryItemBox{
            margin: 10px;
            width: 100px;
            height: 100px;
            text-align: center;
            align-items: center;
       &:hover{
                img{
                    color: #8b009a;
           

                }
                svg{
                    color: #4c3b1b;
                }
                .productList__text{
                    color: #349a87;
                    height: 70px;
                }
            }
        }
        &__categoryItemBox.active{
            svg{
            }
            color: #349a87;
        }
       
        @media (max-width: 700px){ 
            &__imgContainer{
                height: 50px;
            }
            &__text{
                font-size: 13px;
                height: 70px;
            }
            &__containerMainCategory{
                display: block;
            }
            &__mainCategory{
                margin: 10px auto;   
         }
        }
        @media (max-width: 400px){ 
            padding: 5px 0;
            &__container{
                max-width: 1300px;
                margin: 0 auto;
                padding: 0;
            }
        }
}