.showMoreButton{
    width: 100%;
    margin: 30px auto;
    
    &__content{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    padding: 20px;
    border-bottom: 1px solid #e9e9e9;

    &__textBlock{
        margin-left: 10px;
    }
    span{
        font-size: 20px;
    }
    
    }
    svg{
        width: 60px;
        height: 60px;
        transition: transform 3s;
    }
}
.showMoreButton.rotating svg {
    transform: rotate(360deg);
}
.showMoreButton:hover .showMoreButton__content__textBlock {
    color: red;
}