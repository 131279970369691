.renderSubscriptionsMainPage{

    &__title{
        max-width: 300px;
        font-weight: 600;
        font-size: 13px;
    }
    &__explanation{
        margin: 10px auto;
        text-align: center;
        max-width: 200px;
        font-size: 12px;
    }
}