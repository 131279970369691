.renderOrderInfo{
    &__buttonBoxFullWidth{
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2691ec ;
        cursor: pointer;
        bottom: 0;
        transition: 0.3s all;
        &:hover{
            background-color: #2780ce;
        }
    }
    &__button{
        color: #ffffff;
        font-size: 18px;
    }
    &__infoOrderRow{
      border-top: 1px solid #d3d3d3;
      border-bottom: 1px solid #d3d3d3;
    }
    &__totalInfoBox{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 20px auto;

        &__text{
            font-size: 14px;
            font-weight: 700;
        }
        &__desc{
            font-weight: 700;
        font-size: 18px !important;
        }
    }
}