.opacityButtonIcon{
display: flex;
align-items: center;
justify-content: center;
background-color: #f5f5f5;
border: 1px solid #e4e4e4;
width: 200px;
height: 40px;
border-radius: 5px;
margin: 10px auto;
cursor: pointer;
span{
    transition: all 0.3s;
    color: #3e77aa;
    font-size: 16px;
}
svg{
    height: 22px;
    width: 22px;
    margin: 0 10px;
}
&:hover{
    span{
        color: #48a3f2;
    }
}
}
.mainButtonSite{
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
background-color: #349a87;
width: 200px;
height: 40px;
border-radius: 3px;
margin: 10px auto;
span{
    color: white;
}
svg{
    height: 25px;
    width: 25px;
    color: white;
    margin: 0 10px;
}

&:hover{
    background-color: rgb(51, 193, 167);
    span,svg{
        color: rgb(46, 45, 45);
    }
}
}
.medium{
    width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #349a87;
    height: 40px;
    border-radius: 3px;
    margin: 10px auto;
}


.emptyButton{
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-decoration: none;
    &:hover {
        svg, span{
            color: #d51c1c;
        }
    }
    span{
        transition: 0.3s;
        color: #3e77aa;
}
svg{
    transition: all 0.3s;
    height: 25px;
    width: 25px;
    margin: 0 10px;
    color: #3e77aa;
}

}