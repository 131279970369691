.renderDropdownDesc{
    &__titleProductItems{
        font-weight: 600;
        font-size: 18px;
    }
    &__productItems{
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__totalAmount{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 90%;
        margin: 0 auto;
        padding-bottom: 10px;
        flex-wrap: wrap;
        &__title{
            font-size: 20px;
        }
        &__sum{
            font-size: 18px;
            color: #808080;
        }
    }
    &__orderFinalContent{   

        background-color: #fafafa8b;
        padding: 10px;
        &__row{
            display: flex;
            align-items: center;
            justify-content: space-between;

        }
       
        &__text{
            display: flex;
            flex-direction: row;
            align-items: center;
            // margin-bottom: 16px;
            font-size: 14px;
            color: #797878;
            min-width: 50%;
        }

    }
    &__buttonContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin: 10px auto;
    }

    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      
    }
    &__addressInfoName{
        color: gray;
        position: relative;
        
        &::before{
            content: '';
            height: 1px;
            width: 20px;
            background: red;
            // position: absolute;
        }
    }
    &__orderHistoryButton{
        background: none;
        border: none;
        color: #3e77aa;
        cursor: pointer;
        margin: 0;
        padding: 0;
        text-decoration: none;
    
        &:hover{
            color: #d80707;
        }
    }
}