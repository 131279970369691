.miniModalRight{
    position: fixed;
    top: 0;
    right: -200px; /* изменено с -500px на -200px */
    height: 100vh;
    width: 350px;
    background-color: #64aad3;
    border-radius: none;
    box-shadow: 0 4px 10px 0 #5b5b5b; 
    color: white;
    font-family: Montserrat;
    z-index: 9999999;
    &__userFullName{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 1.2px;
    }
    &__contentModal{
        padding: 10px 5px;
        position: relative;
    }
    &__MHeaderTitle{
        text-align: center;
    }
    &__buttonContainer{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 14px auto;
    }
    &__button{
        border: 1px solid white;
        padding: 5px 10px;
    }
    &__MiniModalNumber{
        text-align: center;
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        font-size: 18px;
    }
    @media(max-width: 500px) {
        width: 100%;
    }
}
.miniModalRight.visible {
    transition: all 1s;
    right: 0;
}