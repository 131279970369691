.statusColorText{
    &__statusOrder{
        margin-left: 20px;
        font-weight: 600;
        letter-spacing: 2px;
    }
    &__statusOrder.mini{
        width: 120px;
    }
    &__statusOrder.gray{
        color: rgb(135, 135, 135);
    }
    &__statusOrder.green{ 
        color: #00a046;
    }
    &__statusOrder.greenDark{
        color: #00a6ff;
    }
    &__statusOrder.orange{ 
        color: orange;
    }
    &__statusOrder.blue{ 
        color: #0033ff;
    }
    &__statusOrder.red{ 
        color: red;
    }
    &__statusOrder.gold{
        color: #778ce8;
    }
    &__heading{
        display: block;
        position: relative;
    }
    &__heading::before{
        position: absolute;
        left: 0;
        top: -25px;
        display: block;
        height: 50px;
        width: 8px;
        border-radius: 50px;
        transition: all .2s ease-in-out;
        background-color: rgb(0, 160, 70);
        content: "";
    }
    &__heading.mini::before{
        height: 30px;
        top: 0;
        bottom: 0;
    }
    &__heading.gray::before{
        background-color: gray;
    }
    &__heading.red::before{
        background-color: red;
    }
    &__heading.green::before{
        background-color: #00a046;
    }
    &__heading.greenDark::before{
        background-color: #00a6ff;
    }
    &__heading.orange::before{
        background-color: orange;
    }
    &__heading.blue::before{
        background-color: #0033ff;
    }
    &__heading.gold::before{
        background-color: #778ce8;
    }
}
