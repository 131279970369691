.checkboxText{


    span{
        font-size: 13px;
        margin-top: 10px;
    }

    &__modalContainer {
        min-width: 250px;
        max-width: 330px;
        padding: 10px;
        min-height: 500px;
        background: #5b5b5b;
        border-radius: 4px;
        margin: 0 auto;
      }
}