.grayButtonIcon{
    display: flex;
    width: 150px;
    background-color: #fff3;
    color: #fff;
    height: 45px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    cursor: pointer;
    &:hover{
        background-color: rgba(255, 255, 255, 0.333);


        svg{
    animation: lds-dual-ring 1.3s linear infinite;

            @keyframes lds-dual-ring {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
        }
    }
    svg{
        height: 25px;
        width: 25px;
        margin-bottom: 1px;
        margin-left: 5px;
    }
span{
    font-size: 17px;
}
            @media (max-width: 400px){ 
              width: 90px;
              height: 40px;
              margin-left: 10px;
              svg{
                height: 20px;
                width: 20px;
                margin-bottom: 1px;
                margin-left: 5px;
            }
              span{
                font-size: 14px;
            }
            }
}