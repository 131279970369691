.scroll-block{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 60px;
    position: fixed;
    bottom: 20px;
    left: 10px;
    background:#349a87;
    padding: 10px;
    cursor: pointer;
    color: white;
    transition: 0.3s all;
    border: 1px solid white;
    opacity: 0.8;
    &:hover{
    background: rgb(88, 88, 88);
    }
    svg{
        font-size: 40px;
        margin: 0 auto;
    }
}