.sortProduct{
    max-width: 800px;
    margin: 0 auto;

    &__row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

    }
    &__selectContainer1{
        margin: 0 auto;
        max-width: 300px;
    }
    &__multipleSelect{
        display: flex;
        justify-content: center;
    }
    &__mainSortContent{
        display: block;
    }
    &__leftModal{
        display: none;
    }
    &__modalContainer{
        display: none;
    }
    &__modalChildren{
        display: none;
    }
    @media(max-width: 500px){
        &__modalContainer{
            display: block;
        }
        &__headerBox{
            border-bottom: 1px solid rgb(207, 207, 207);
            margin: 10px auto;
            &__content{
                margin: 0 auto;
                width: 90%;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &__title{
                font-size: 18px;
            }
            &__closeIcon{

                svg{
                    transition: all 0.5s;
                }
                &:hover{
                    color: red;
                }
            }
        }
        &__modal{
            position: fixed;
            transition: all 0.5s;
            background: white;
            border: 1px solid black;
            left: -500px;
            height: 100%;
            top: 0;
            bottom: 0;
            z-index: 999999;

            &__list{
                justify-content: space-between;
                align-items: center;
                display: flex;
                font-size: 16px;
                padding: 5px 10px;
                width: 100%;
                border-bottom: 1px solid rgb(207, 207, 207);
                border-top: 1px solid rgb(207, 207, 207);
                margin-bottom: 5px;

            }
        }
        &__modalChildren{
            display:block;
            transition: all 0.5s;
            position: fixed;
            background: white;
            border: 1px solid black;
            left: -500px;
            top: 0;
            bottom: 0;
            z-index: 99999999;
            height: 100%;
            &__showContentButtonBlock{
                display: flex;
                align-items: center;
                padding: 10px;
                justify-content: space-around;
                border-top: 1px solid rgb(207, 207, 207);
                bottom: 0;
                position: absolute;
                width: 100%;
            }
            &__content{
                padding-left: 10px;
            }
        }
        &__modalChildren.visible{
            left: 0;
            width: 80vw;
        }

        &__modal.visible{
            left: 0;
            width: 80vw;
        }
        &__mainSortContent{
            display: none;
        }
        &__leftModal{
            display: block;
        }
        &__buttonModalBlock{
            width: 90%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

        }
        &__buttonFilter{
            text-align: center;
            background: #3f3f3f;
            color: white;
            min-width: 100px;
            padding: 5px 10px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            &__text{
                margin-left: 10px;
            }
        }
        &__selectContainer1{
            margin: 0;
            max-width: 300px;
        }
    }
}