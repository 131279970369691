.radioArrayItems{ 

    &:hover{
        .custom-radio__label{
            strong{
                color: #2691ec;            
            }
        }
        .custom-radio [type="radio"]:checked + .custom-radio__label:before,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:before {
            background: #2691ec;
        }
    }
    label {
    float: left;
  }
  // .custom-radio__label{
    // margin-left: 20px;
  // }
  .custom-radio [type="radio"]:checked,.custom-radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  
  .custom-radio [type="radio"]:checked + .custom-radio__label,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label {
    position: relative;
    padding: 6px 0 4px 38px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    margin-bottom: 4px;
  }
  
  .custom-radio [type="radio"]:checked + .custom-radio__label:before,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  
  .custom-radio [type="radio"]:checked + .custom-radio__label:after,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #2691ec;
    position: absolute;
    top: 8px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
  }
  
  .custom-radio [type="radio"]:not(:checked) + .custom-radio__label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  
  .custom-radio [type="radio"]:checked + .custom-radio__label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}
