.linkButtonBlock{
    max-width: 100%;
    background-color: #ffffff ;
    &__container{
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    margin: 10px auto;

    }

 @media (max-width: 600px){ 
    max-width: 100%;
    background-color: #ffffff ;
    &__container{
        max-width: 1200px;
        display: block;
        align-items: center;
        justify-content: space-between;
    margin: 10px auto;

    }
    a{
        text-decoration: none;
        transition: 0.3s all ;
        cursor: pointer;
        &:hover{
            color: #7448ff;
        }
    }

 &__linkBox{
    display: block;

    svg{
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
    
}
 }

}

