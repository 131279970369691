.mainPage {
    position: relative; /* установите позицию родительского элемента, чтобы дочерний элемент ::before мог быть позиционирован относительно него */
    background-image: url('../../assets/1578425021_2-3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    &__mainContent{
        max-width: 1000px;
        margin: 0 auto;
        padding: 80px 20px;
    }
    &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 10;
    }
    
    &__item {
    position: relative;
    margin: 50px 20px ;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2); 
    border: 1px solid rgba(255, 255, 255, 0.6);
    &:hover{
            box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.4); 
    }
}
    
    &__imgContainer {
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            transition: all 0.5s;
          }
          
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            transition: all 0.5s;
            z-index: 1;
          }
          
          &:hover {
            img {
              transform: scale(1.05);
            }
           
          
            &::before {
              background-color: rgba(0, 0, 0, 0.4);
              z-index: 2; 
            }
          }
}

    &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: aliceblue;
    text-shadow: 1px 1px 1px black;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.4;
    font-family: Montserrat;
    transition: all 0.3s;
    max-width: 200px;
    white-space: pre-wrap;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    }

    &__buttonBlock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: aliceblue;
    transition: all 0.3s;
    letter-spacing: 1.2px;
    font-size: 25px;
    border: 2px solid aliceblue;
    padding: 10px 30px;
    font-weight: 600;
    font-family: Montserrat;
    z-index: 2;
        &:hover {
            letter-spacing: 6px;
            padding: 10px 13px;
          }
          }
          @media(max-width:800px){
            &__row {
                justify-content: center;
                }
            }
          @media(max-width: 500px){
            &__imgContainer {
                width: 250px;
                height: 250px;
          }
}
}
          .mainPage::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7); /* цвет затемнения, где последнее значение (0.5) - это прозрачность, где 0 - полностью прозрачный, а 1 - полностью непрозрачный */
        }