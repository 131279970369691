.tabs{

    background-color: #f5f5f5;
    margin-bottom: 32px;
    position: relative;

    &__container{
        padding-left: 32px;
    padding-right: 32px;
    }
&__list{
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    overflow: auto;
}

&__item{
    margin-right: 16px;
    transition: color 0.1s ease-in-out;
}
.active{
    box-shadow: inset 0 -2px 0 0 #00a046;
    color: #00a046;
}
&__link{
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
}

&__link:hover{
    box-shadow: inset 0 -2px 0 0 #00a046;
    color: #00a046;
}
.inactive {
    box-shadow: inset 0 -2px 0 0 #00a046;
    color: #00a046;
}

}
