.iconButtonClose{
    position: relative;
    &__iconContainer{
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
        svg{
            font-size: 25px;
        }
        &:hover{
            color: rgb(119, 140, 232);

    }
}
}