.renderAddPictures{

    &__buttonBox{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img{
            width: auto;
            height: 100px;
            margin-bottom: 10px;
        }
     
      }
    &__button{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: orange;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: poiner;
    svg{
        font-size: 25px;
        color: black;
    }
    &:hover{
        background-color: rgb(196, 196, 196);
    }
    }

    &__imgBox{
        width: 100px;
        height: 100px;

        img{
            height: auto;
            width: 100%;
        }
    }
    .row{

    }
}