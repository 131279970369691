.loggedInMenu{
    margin: 20px 0;
    &__list{
        width: 100%;
        cursor: pointer;
        p{
            padding: 10px;
        }
        &:hover{
            // color: black;
            background-color: rgba(0,0,0,.2);
            p{
                color: #ff4747;
                background-color: rgb(232, 232, 232);
            }
        }
           
    }
    &__list.rightModal{
        text-decoration: none;
        p{
            font-size: 20px;
        }
        &:hover{
            // color: black;
            background-color: rgba(0,0,0,.2);
            p{
                color: #0f0f0f;
                background-color: rgb(232, 232, 232);
            }
        }
        }
}
   

