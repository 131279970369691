.headerMainPage{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding: 10px 0; 
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2);
    &__rowContent{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__title, &__signInBlock{
        color: white;
    }
    &__title{
        font-size: 20px;

        img{
            height: 70px;
            width: auto;
            border-radius: 10px;
            cursor: pointer;
        }
    }
}

.headerMainPage.background{
    background-color: #2e353f;
}