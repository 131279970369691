.zoomIcon{
    opacity: 0;
    &__iconBlock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        transition: opacity 0.3s ease;
        svg{
           width: 40px;
           height: 40px;
           visibility: visible;
           color: rgba(255, 255, 255, 0.7);
         &:hover{
           width: 45px;
           height: 45px;
           visibility: visible;
           color: rgb(255, 255, 255);
         }
       }
      }
}
.zoomIcon.visible{
    opacity: 1;
}
