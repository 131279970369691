.emailNewsletterPage{
    // width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  &__captcha{
    margin: 0 auto;
  }
    h1{
        font-size: 24px;
        line-height: 1.25;
        text-align: center;
        color: #333333;
        margin-bottom: 18px;
    }
    h2{
        font-size: 18px;
        line-height: 1.44;
        text-align: left;
        color: #707070;
        margin-bottom: 10px ;
    }
    p{
        font-size: 18px;
        line-height: 1.44;
        text-align: left;
        color: #bababa;
    }

    &__contentContainer{
        background-color: rgb(204, 204, 204);
        // height: 500px;
        margin: 50px auto;
        max-width: 550px;
        padding: 40px;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 4px 10px 0 #bababa;
        border: solid 1px #e5e5e5;
    }
}